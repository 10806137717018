const config = {
	redirectURI: document.location.pathname !== '/' ? document.location.origin + document.location.pathname : document.location.origin,
	cognito: {
		userPoolId: 'us-east-1_H4z1NNurl',
		region: 'us-east-1',
		clientId: '18koh65go65tc8o6rm6qqdrnsf',
		identityPoolId: 'us-east-1:0285e6f4-fe43-4e57-8097-20ce600ee1f4'
	},
	fb: {
		appId: '180031860012956'
	},
	apple: {
		serviceId: 'com.maverickmaven.cognito2'
	},
	ln: {
		domain: 'dev-f5ss9qz3.auth0.com',
		clientID: '04ZMVN51DKzrs8qtzWgHCPy3VLeTFHHA'
	},
	lambdaAPI: 'https://ua1mvrnoik.execute-api.us-east-1.amazonaws.com/production/',
	serverAPI: 'http://52.90.82.235:8888/geotourdata/',
	s3Bucket: 'https://boximg-420.s3.amazonaws.com/',
	dbSyncDelay: 2000,
}

export {
	config
}

/**
 * Update this URL in AWS Identity Pool App Client Callback URL(s), Sign out URL(s)
 * Update this URL in Facebook developer account Callback URL(s)
 * Update this URL in Linkedin developer account Callback URL(s)
 * Update this URL in Auth0 account Callback URL(s)
 * Update this URL in Apple developer account Callback URL(s)
 */
