import React from 'react';
import {Link} from "react-router-dom";
import {Mutation} from "@apollo/client/react/components";
import {validateInput, passwordStrengthValidator, matchValues} from '../../lib/validation';
import {gql} from "@apollo/client";
import {graphErrorHandler} from "../../lib/graphErrorHandler";
import submitHandler from "../../lib/submitHandler";

const registerMutation = gql`
mutation registerMutation($User_Name: String, $email: String!, $password: String!) {
	register(User_Name: $User_Name, email: $email, password: $password) {
		token
		user {
			User_Name
			User_FirstName
			User_LastName
			email
		}
	}	
}
`;

export default class Signup extends React.Component{

	validateInput = async (e) => {
		await validateInput(e.currentTarget);
	}
	validatePassword = async (e) => {
		await passwordStrengthValidator(e.currentTarget)
	}

	confirmPassword = async (e) => {
		await matchValues(e.currentTarget)
	}

	onSuccess = (data) => {
		localStorage.setItem('token', data.register.token);
		window.location = '/profile?onboarding=true';
	}

	render() {
		return(
			<>
				<div className="row no-gutters animated fadeInLeft min-vh-100 align-items-center justify-content-center">
					<div className="page-form-wrap col">
						<div className="page-form">
							<h1 className="text-uppercase text-900 text-center mt-0 mb-4">Register</h1>

							<Mutation mutation={registerMutation} onCompleted={this.onSuccess} onError={(error) => { graphErrorHandler(error)}}>
								{(mutation) => (
									<form data-success-message="#post-submit-note" noValidate onSubmit={(e) => { submitHandler(e, mutation)}}>
										<div id="general-error" />
										<div>
											<div className="form-group with-icon floating-label-group">
												<input className="form-control"
													   type="text"
													   name="User_Name"
													   id="name"
													   placeholder="Name"
													   data-placement="top"
													   data-trigger="hover"
													   onBlur={this.validateInput}
													   required />
												<div className="after-control">
													<label className="floating-label" htmlFor="name">Name</label>
													<span className="focus-effect" />
													<div className="popover bs-popover-top">
														<div className="arrow" />
														<div className="popover-body">
															<span className="no-wrap">Type your full name</span>
														</div>
													</div>
													<span className="form-control-icon lnr lnr-user" />
												</div>
											</div>

											<div className="form-group with-icon floating-label-group">
												<input className="form-control"
													   type="text"
													   name="email"
													   id="email"
													   placeholder="Email"
													   onBlur={this.validateInput}
													   required />
												<div className="after-control">
													<label className="floating-label" htmlFor="email">Email</label>
													<span className="focus-effect" />
													<div className="popover bs-popover-top">
														<div className="arrow" />
														<div className="popover-body">
															<span className="no-wrap">Enter a valid email</span>
														</div>
													</div>
													<span className="form-control-icon lnr lnr-envelope" />
												</div>
											</div>

											<div className="form-group with-icon floating-label-group">
												<input className="form-control"
													   id="registerPassword"
													   type="password"
													   name="password"
													   onChange={this.validatePassword}
													   onFocus={this.validatePassword}
													   data-progressbar="#password-strength-progress"
													   placeholder="Password" required />
												<div className="after-control">
													<label className="floating-label" htmlFor="registerPassword">Password</label>
													<span className="focus-effect" />
													<div className="popover bs-popover-top">
														<div className="arrow" />
														<div className="popover-header">Password Requirements</div>
														<div className="popover-body">
															<ul className="error-dependant-list">
																<li className="no-wrap error-length">Between 7-16 Characters</li>
																<li className="no-wrap error-uppercase">An Upper Case Letter</li>
																<li className="no-wrap error-number">A Number</li>
																<li className="no-wrap error-special-characters">At Least 1 of the Following (_,#,%,*,@)</li>
																<li className="no-wrap error-prohibited-characters">None of the Following ($,&,=,!)</li>
															</ul>
															<div className="progress">
																<div id="password-strength-progress" className="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
																</div>
															</div>
														</div>
													</div>
													<span className="form-control-icon lnr lnr-lock" />
												</div>
											</div>
											<div className="form-group with-icon floating-label-group">
												<input className="form-control popover-only-on-error"
													   type="password"
													   name="confirm_password"
													   id="confirmPassword"
													   placeholder="Confirm Password"
													   onChange={this.confirmPassword}
													   onFocus={this.confirmPassword}
													   data-match="#registerPassword"
													   required />
												<div className="after-control">
													<label className="floating-label" htmlFor="confirmPassword">Confirm Password</label>
													<span className="focus-effect" />
													<div className="popover bs-popover-top"><div className="arrow" />
														<div className="popover-body">
															<span className="no-wrap error-label">Passwords do not match!</span>
														</div>
													</div>
													<span className="form-control-icon lnr lnr-lock" />
												</div>
											</div>
											<div className="form-group btn-wrap">
												<button type="submit"
														className="btn btn-block btn-md btn-primary"
														title="Register">
													<span className="btn-text">Register</span>
													<i className="btn-icon far fa-spinner-third fa-spin" />
												</button>
											</div>

										</div>
										<div className="text-center fonts-secondary color-light">
											Already Registered? <Link className="text-borderline color-light" to="/">Login</Link>
										</div>
									</form>

								)}
							</Mutation>


							<div id="post-submit-note" className="text-center post-submit-message color-light">
								<p className="mb-0 ">Please Check your email for a verification link</p>
								After verification, come back here and <Link className="text-borderline color-light" to="/">Login</Link>
							</div>
						</div>
					</div>
				</div>
			</>

		)
	}

}
