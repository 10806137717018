import React from "react";
import { Link } from "react-router-dom";
import {seriesQuery} from "../../graphQL/query/series.query";
import Loading from "../partials/Loading";

export default class ExistingTours extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      User: '',
      seriesList: [],
      loading: true
    }
  }


  componentDidMount() {
    this.getTours();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.viewer && this.state.loading) {
      this.getTours();
    }
  }

  getTours = async () => {
    if(this.props.viewer){
      console.log(this.props.viewer);
      await this.props.apolloClient.query({
        query: seriesQuery,
        variables: {
          userId: this.props.viewer._id
        }
      }).then(result => {
        if(!result.loading){
          console.log(result.data);
          if(result.data && result.data.series && result.data.series.length) {
            console.log(result.data.series);
            this.setState({
              seriesList: result.data.series,
              loading: false
            });
          } else {
            this.setState({
              loading: false
            });
          }
        }
      }).catch(e => {
        console.log(e);
        this.setState({
          loading: false
        });
      });
    }
  }

  onChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {

    return (
      <>
        {this.state.loading ?
            <Loading/> :
            <>
              <div className="row no-gutters animated fadeInLeft min-vh-100 align-items-center justify-content-center">
                <div className="col-12 col-lg-10 col-xl-9">
                  <div className="page-content position-relative">
                    <Link
                        to="/dashboard"
                        title="Close Tour"
                        className="closeAndGoBack"
                    >
                      <i className="fal fa-times" />
                    </Link>
                    <div className="previous-tours-wrap p-3">
                      <Link className="float-right btn btn-info" to="/tour">Add New</Link>
                      <h1 className="mt-0 mb-5 text-900">My Tours</h1>
                      <ul />
                      <div className="previous-tours loading">
                        {this.state.seriesList.map((tour, i) => {
                          return (
                              <Link
                                  to={'/tour?edit=' + tour._id}
                                  className="btn btn-shadow with-icon btn-block text-left"
                                  key={"tour-" + tour._id}
                              >
                                {tour.Series_Name}{" "}
                                {tour.Status === 'draft' &&  <span className="badge badge-warning text-uppercase ml-4">{tour.Status}</span>}
                                {tour.Status === 'review' &&  <span className="badge badge-info text-uppercase ml-4">Under Review</span>}
                                {tour.Status === 'publish' &&  <span className="badge badge-success text-uppercase ml-4">{tour.Status}</span>}

                                <i className="fal fa-angle-right" />
                              </Link>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
        }
      </>
    );
  }
}

/*https://codepen.io/nat-davydova/pen/PoYXZxg*/
