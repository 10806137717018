import {appendErrorNode} from "./errorHandler";

export const graphErrorHandler = async (err) => {
	const errors = Object.entries(err)[0] && Object.entries(err)[0][1] ? Object.entries(err)[0][1] : [];
	let errorsDisplayed = false;
	let value;
	for (value of Object.entries(errors)) {
		value = value[1] ? value[1] : value[0]; 
		if(value.extensions?.code === 'UNAUTHENTICATED') {
			errorsDisplayed = true;
			appendErrorNode(value.message);
		} else{
			if(value.extensions){
				if(value.extensions.exception){
					const exceptions = value.extensions.exception;
					const errorKeyValues = exceptions.keyValue; 
					const errTrace = exceptions.stacktrace[0];
					if(errTrace.toString().includes('MongoError')) {
						if(errTrace.toString().includes('duplicate')) {
							errorsDisplayed = true;
							Object.keys(errorKeyValues).forEach(function(key) {
								appendErrorNode(errorKeyValues[key]  + " already exists. Please enter a different value.");
							});
						} else {
							Object.keys(errorKeyValues).forEach(function(key) {
								errorsDisplayed = true;
								appendErrorNode(errorKeyValues[key]  + " is an invalid value.");
							});
						}
					}
				}
			} else {
				errorsDisplayed = false;
			}
		}
	}

	if(!errorsDisplayed) {
		let errString = err.toString().replace('Error: GraphQL error: ', '');
		errString = errString.replace('Received status code 400', ''); 
		errString = errString.replace('Response not successful:', 'Operation Failed.'); 
		if(errString.includes('Cannot return null for non-nullable field')){
			errString = 'Unable to find requested data.'
		}else if(errString.includes('failed:')){
			errString = errString.split('failed:')[1];
			if(errString.includes('to be unique')) {
				const existingValue = errString.split("Value:")[1];
				errString = existingValue + " already exists."
			}
		}
		appendErrorNode(errString);
	}
};

