import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import ProfilePicUpload from "./ProfilePicUpload";
import "cropperjs/dist/cropper.css";

export default function Popup(props) {
  let [openPopup1, setOpenPopup1] = useState(false);
  let { title, children, openPopup, imageFile } = props;
  useEffect(() => {
    setOpenPopup1(openPopup);
  }, [openPopup]);

  function close(e) {
    if(e){
      e.preventDefault();
    }
    setOpenPopup1(false);
  }

  return (
    <Dialog open={openPopup1}>
      <div className="profile__image">
        <DialogContent>
          <ProfilePicUpload
            handler={props.handler}
            imageFile={props.imageFile}
            apolloClient={props.apolloClient}
          />
          <button
            style={{ marginTop: "5px" }}
            className="btn btn-wide btn-secondary ml-auto "
            onClick={close}
          >
            Cancel
          </button>
        </DialogContent>
      </div>
    </Dialog>
  );
}
