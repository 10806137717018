import React from 'react';

export default class WikiPages extends React.Component{

	constructor(props) {
		super(props);
		this.state = {
			wikiPages: [],
			apiEndPoint: 'https://en.wikipedia.org/w/api.php?format=json&origin=*&action=query&list=geosearch&gsprimary=all&gsradius=2000&gscoord=',
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.lat !== this.props.lat || prevProps.lng !== this.props.lng){
			this.getWikiArticles();
		}
	}	

	componentDidMount = async () => {
		if(this.props.lat && this.props.lng && this.props.lat !== '' && this.props.lng !== ''){
			this.getWikiArticles();
		}
	}

	getWikiArticles = async => {
		if(this.props.lat && this.props.lng){
			const lat = this.props.lat;
			const lng = this.props.lng;
			const limit = this.props.limit ? this.props.limit : '15';
			let wikiQueryUrl = this.state.apiEndPoint + lat + '%7C' + lng + '&gslimit=' + limit ;
			fetch(wikiQueryUrl)
				.then(response => response.json())
				.then(async data => {
					let wikiList = data['query'] ? data['query']['geosearch'] : [];

					await this.setState({
						wikiPages: wikiList
					});
					setTimeout(() => {
						const parent = document.getElementById(this.props['id'] ? this.props['id'] : "wiki-pages");
						if(parent){
							const radioToCheck = parent.querySelector('input[value="'+ this.props.wikiID +'"]');
							if(radioToCheck && !radioToCheck.checked) {
								radioToCheck.checked = true;
								const validationHelper = parent.querySelector('.wiki-page-validator');
								if(validationHelper) {
									validationHelper.value = this.props.wikiID;
								}
							}
						}
					}, 1000);
				})
				.catch((error) => {
					console.error('Error:', error);
				});
		}
	}

	onChange = e => {
		const parent = document.getElementById(this.props['id'] ? this.props['id'] : "wiki-pages");
		const selectedPage = parent.querySelector('input[name="Series_wikiID"]:checked').value;
		parent.querySelector('.wiki-page-validator').value = selectedPage;
		if(this.props.callback) {
			this.props.callback({
				wikiID: selectedPage
			});
		}
	}

	render() {
		return(
			<div id={this.props['id'] ? this.props['id'] + '-wrap' : "wiki-pages-wrap"}>
				<div id={this.props['id'] ? this.props['id'] : 'wiki-pages'} className="wiki-pages-list">
					<div className="mb-3 fonts-secondary">
						{this.state.wikiPages.length > 0 ? "Please select one of the following wikipedia articles that is more about this tour." :  "No Wiki connections found with respect to your selected central point."}
					</div>
					<div className="radio-control-list">
						{this.state.wikiPages.length > 0 ? <input className="wiki-page-validator form-control" required type="hidden"/> : '' }
						{this.state.wikiPages.map((page, i) => {
							if(!((page.title.toLowerCase()).startsWith('list of') || (page.title.toLowerCase()).startsWith('national register'))) {
								return (
									<div key={"wiki-page-" + i}
									     className="form-group floating-label-group custom-radio-control">
										<input type="radio"
										       value={page.pageid}
										       onChange={this.onChange}
										       className="form-control"
										       name="Series_wikiID"
										       id={"wiki-page-" + i}
										       required/>
										<div className="after-control">
											<span className="focus-effect"/>
											<label htmlFor={"wiki-page-" + i}>
												{page.title} - <a target="_blank" title="link opens in a new tab"
												                  className="small" rel="noopener noreferrer"
												                  href={"http://en.wikipedia.org/?curid=" + page.pageid}>Open
												article</a>
											</label>
										</div>
									</div>
								);
							} else {
								return ''
							}
						})}
					</div>
				</div>
			</div>

		)
	}

}

/*
* To get page using paegid: http://en.wikipedia.org/?curid=[pageid]
*/
