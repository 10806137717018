import React from "react";
import { Link } from "react-router-dom";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { generateUsername } from "../../lib/username";
import { getData } from "../../lib/helpers";
import { config } from "../../config";
import ProfilePicPopup from "../partials/ProfilePicPopup";
import {graphErrorHandler} from "../../lib/graphErrorHandler";
import {Mutation} from "@apollo/client/react/components";
import {userMutation} from "../../graphQL/mutation/user.mutations";
import LanguageSelect from "../partials/LanguageSelect";
import submitHandler from "../../lib/submitHandler";
import AlertModal from "../partials/AlertModal";

export default class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ipLocation: {},
      openPopup: false,
      imageFile: undefined,
      _id: "",
      User_Username: "",
      User_FirstName: "",
      User_LanguageID: 1,
      User_LastName: "",
      User_Name: "",
      User_Active: "",
      User_Pronounce: "",
      User_Photo: "",
      User_Level: "",
      User_Venmo: "",
      User_Mobile: "",
      User_Email: "",
      User_NickName: "",
      User_Bio: "",
      User_Location: "",
      User_Email_verified: "",
      showAlertModal: false,
      isOnBoarding: false
    };
    this.handler = this.handler.bind(this);
  }
  async handler(data) {
    if(data.success){
      this.setState({
        openPopup: false,
        User_Photo: data.imageUrl
      });
    } else {
      this.setState({
        openPopup: false
      });
      alert("Failed to upload your profile picture, please try again later.");
    }
  }

  componentDidMount = async () => {
    const urlParams  = new URLSearchParams(window.location.search);
    if(urlParams.get('onboarding')){
      this.setState({
        isOnBoarding: true
      })
    }
    if(this.props && this.props.viewer){
      console.log(this.props.viewer);
      this.setState({
        ...this.props.viewer
      })
    }
    if(!(this.props.viewer.User_Username && this.props.viewer.User_Username.length > 3)){
      document.getElementById('username-generator').click();
    }
    if(!(this.props.viewer.User_Location && this.props.viewer.User_Location.length)){
      try {
        const userIpLocation = await getData(config.lambdaAPI + "location");
        await this.setState({
          ipLocation: userIpLocation,
          User_location: userIpLocation.city + ", " + userIpLocation.country,
        });
      } catch (e) {
        console.warn("Failed to get location");
      }
    }
    setTimeout(() => {
      this.bioCheck(false);
    }, 1000)
  };

  validateInput = async (e) => {
    //await validateInput(e.currentTarget);
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  generateUsername = async (e) => {
    const targetInput = document.getElementById(e.currentTarget.getAttribute('data-target'));
    if (targetInput) {
      generateUsername(targetInput);
    }
  };

  bioCheck = async (e) => {
    const bio = e ? e.currentTarget : document.getElementById('User_Bio');
    const characterCount = bio.value.length,
      current = document.getElementById("bio-current-length"),
      maximum = document.getElementById("bio-max-length");

    current.innerText = characterCount;
    if (characterCount < 70) {
      current.style.color = "#666";
    }
    if (characterCount > 70 && characterCount < 90) {
      current.style.color = "#6d5555";
    }
    if (characterCount > 90 && characterCount < 100) {
      current.style.color = "#793535";
    }
    if (characterCount > 100 && characterCount < 120) {
      current.style.color = "#841c1c";
    }
    if (characterCount > 120 && characterCount < 139) {
      current.style.color = "#8f0001";
    }
    if (characterCount >= 140) {
      current.style.color = "#8f0001";
      maximum.style.color = "#8f0001";
    } else {
      maximum.style.color = "#666";
    }
  };

  initiateUpload = (e) => {
    e.preventDefault();
    this.setState({
      openPopup: true,
      imageFile: e.currentTarget.files[0],
    });
  };


  onSuccess = async (data) => {
    this.setState({
      showAlertModal: true
    });
    setTimeout(() => {
      if(this.state.isOnBoarding){
        document.getElementById('toDashboard').click();
      } else {
         this.setState({
          showAlertModal: false
        });
       }
    }, 2000);

  }

  render() {
    return (
      <>
        {
          this.state.showAlertModal && <AlertModal title="Success!" body="<p>Your profile has been updated." iconClass="fal fa-check-circle" />
        }
        {
          this.state.showAlertModal && this.state.isOnBoarding && <Link to="/dashboard" id="toDashboard" className="pseudo-link">Redirecting to dashboard</Link>
        }
        <div className="row no-gutters animated fadeInLeft min-vh-100 align-items-center justify-content-center">
          <div className="col-12 col-xl-8">
            <div className="page-form">
              <Link
                  to="/dashboard"
                  title="Go to Dashboard"
                  className="closeAndGoBack"
              >
                <i className="fal fa-times" />
              </Link>
              <Mutation mutation={userMutation} onCompleted={this.onSuccess} onError={(error) => { graphErrorHandler(error)}}>
                {(mutation) => (
                    <form noValidate onSubmit={e => {submitHandler(e, mutation)}}>
                      <div id="general-error" />
                      <div className="row flex-row-reverse">
                        <div className="col-12 col-md-6">
                          <div className="upload-picture">
                            <input type="hidden" name="_id" value={this.state._id} />
                            {this.state.User_Photo && <input type="hidden" name="User_Photo" value={this.state.User_Photo}  />}
                            <input onChange={e => {this.initiateUpload(e)}} type="file" className="upload-action" />
                            <div className="current-image">
                              <img src={
                                this.state.User_Photo && this.state.User_Photo.length > 5 ? config.s3Bucket + this.state.User_Photo : "./imgs/profile_icon.jpg"
                              } alt="profile" />
                            </div>
                            <div className="picture-caption">
                              {
                                this.state.User_Photo && this.state.User_Photo.length > 5 ? "Change profile picture" : "Upload profile picture"
                              }
                            </div>
                          </div>
                          <div className="input-group floating-label-group">
                            {
                              this.state.User_Username && this.state.User_Username.length > 3 ? <>
                                    <input
                                        type="text"
                                        id="User_Username"
                                        className="form-control"
                                        placeholder="Username"
                                        readOnly="readOnly"
                                        defaultValue={this.state.User_Username}
                                    />
                                    <div className="after-control">
                                      <label
                                          className="floating-label"
                                          htmlFor="User_Username"
                                      >
                                        Username
                                      </label>
                                      <span className="focus-effect"/>
                                      <div className="popover bs-popover-top">
                                        <div className="arrow"/>
                                        <div className="popover-body">
                                <span className="no-wrap">
                                  Your username
                                </span>
                                        </div>
                                      </div>
                                    </div>
                              </> :
                                  <>
                                    <input
                                        type="text"
                                        name="User_Username"
                                        id="User_Username"
                                        className="form-control"
                                        placeholder="Username"
                                        required="required"
                                        defaultValue={this.state.User_Username ? this.state.User_Username : ""}
                                    />
                                    <div className="after-control">
                                      <label
                                          className="floating-label"
                                          htmlFor="User_Username"
                                      >
                                        Username
                                      </label>
                                      <span className="focus-effect"/>
                                      <div className="popover bs-popover-top">
                                        <div className="arrow"/>
                                        <div className="popover-body">
                                <span className="no-wrap">
                                  Username must be unique
                                </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="input-group-append">
                                      <button
                                          id="username-generator"
                                          data-target="User_Username"
                                          onClick={e => {
                                            this.generateUsername(e);
                                          }}
                                          className="btn btn-dark less-dark"
                                          type="button"
                                      >
                                        Regenerate
                                      </button>
                                    </div>
                                  </>
                            }

                          </div>
                          <div className="form-group floating-label-group">
                            <input
                                type="text"
                                className="form-control"
                                name="User_NickName"
                                id="User_NickName"
                                placeholder="Nickname"
                                onBlur={this.validateInput}
                                required="required"
                                defaultValue={this.state.User_NickName ? this.state.User_NickName : ""}
                            />
                            <div className="after-control">
                              <label
                                  className="floating-label"
                                  htmlFor="User_NickName"
                              >
                                Nickname
                              </label>
                              <span className="focus-effect"/>
                              <div className="popover bs-popover-top">
                                <div className="arrow"/>
                                <div className="popover-body">
                                <span className="no-wrap">
                                  Enter your Nickname
                                </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group floating-label-group">

                            <LanguageSelect id="User_LanguageID" defaultValue={this.state.User_LanguageID ? this.state.User_LanguageID : ""} className="custom-select form-control" placeholder="Writing Language" name="User_LanguageID"  />

                            <div className="after-control">
                              <span className="focus-effect"/>
                              <div className="popover bs-popover-top">
                                <div className="arrow" />
                                <div className="popover-body">
                                <span className="no-wrap">
                                  Select your prefered language
                                </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group high-z">
                            <IntlTelInput
                                fieldName="User_Mobile"
                                value={this.state.User_Mobile ? this.state.User_Mobile : undefined }
                                containerClassName="intl-tel-input"
                                inputClassName="form-control"
                            />
                          </div>
                          <div className="form-group floating-label-group">
                            <input
                                type="text"
                                id="User_Location"
                                className="form-control"
                                name="User_Location"
                                required="required"
                                placeholder="Hometown (City, Country)"
                                defaultValue={this.state.User_Location && this.state.User_Location.length ? this.state.User_Location : ""}
                                onBlur={this.validateInput}
                            />
                            <div className="after-control">
                              <label
                                  className="floating-label"
                                  htmlFor="User_Location"
                              >
                                Hometown (City, Country)
                              </label>
                              <span className="focus-effect"/>
                              <div className="popover bs-popover-top">
                                <div className="arrow"/>
                                <div className="popover-body">
                                <span className="no-wrap">
                                  Please enter your city followed by your
                                  country.
                                </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group floating-label-group">
                            <input
                                type="text"
                                className="form-control"
                                name="User_Venmo"
                                id="User_Venmo"
                                placeholder="Link to your Venmo or Paypal"
                                defaultValue={this.state.User_Venmo ? this.state.User_Venmo : ""}
                                onBlur={this.validateInput}
                            />
                            <div className="after-control">
                              <label
                                  className="floating-label"
                                  htmlFor="User_Venmo"
                              >
                                Link to your Venmo or Paypal
                              </label>
                              <span className="focus-effect"/>
                              <div className="popover bs-popover-top">
                                <div className="arrow"/>
                                <div className="popover-body">
                                <span className="no-wrap">
                                  Enter your link to Venmo
                                </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group mb-0 floating-label-group">
                          <textarea
                              className="form-control"
                              name="User_Bio"
                              id="User_Bio"
                              maxLength="500"
                              placeholder="Start typing a short bio..."
                              defaultValue={this.state.User_Bio ? this.state.User_Bio : ""}
                              onBlur={this.validateInput}
                              onKeyUp={this.bioCheck}
                          />
                            <div className="after-control">
                              <label className="floating-label" htmlFor="User_Bio">
                                Start typing a short bio...
                              </label>
                              <span className="focus-effect"/>
                              <div className="popover bs-popover-top">
                                <div className="arrow"/>
                                <div className="popover-body">
                                  <span className="no-wrap">Enter your bio</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="clearfix text-right fonts-primary color-light mb-3">
                            <span id="bio-current-length">0</span>/
                            <span id="bio-max-length">500</span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-7">
                          <div className="custom-checkbox-control color-danger small">
                            <label htmlFor="agree-cookies">
                              <input id="agree-cookies" name="AcceptDisclosures" type="checkbox" required />
                              <span>
                              I accept the GDPR, CASIL & All{" "}
                                <Link
                                    to={{
                                      pathname: "/cookies",
                                      state: {prevPath: "/profile"},
                                    }}
                                    className="color-inherit text-underline"
                                >
                                Cookie Disclosures
                              </Link>
                              .{" "}
                            </span>
                            </label>
                          </div>
                        </div>
                        <div className="col-12 col-md-5">
                          <div className="custom-checkbox-control color-danger small">
                            <label htmlFor="agreeToTerms">
                              <input id="agreeToTerms" name="AcceptTerms" type="checkbox" required />
                              <span>I agree to the terms.</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4 mb-3">
                        <div className="col-12 col-sm-6">
                          {
                            this.state.isOnBoarding ?
                                <Link to={"/dashboard"} className="btn btn-md btn-dark btn-block text-uppercase">
                                  Do it later!
                                </Link>
                                :
                                <Link to={"/dashboard"} className="btn btn-md btn-dark btn-block text-uppercase">
                                  Cancel
                                </Link>
                          }
                        </div>
                        <div className="col-12 col-sm-6">
                          <button
                              type="submit"
                              className="btn btn-md btn-primary btn-block text-uppercase mt-sm-0 mt-2"
                          >
                            Update
                            <i className="btn-icon far fa-spinner-third fa-spin"></i>
                          </button>
                        </div>
                      </div>
                      <div className="text-center fonts-secondary">
                        <Link to="/logout" className="btn-logout">
                          Logout
                        </Link>
                      </div>
                    </form>
                )}
              </Mutation>
            </div>
          </div>
        </div>
        <ProfilePicPopup
            handler={this.handler}
            openPopup={this.state.openPopup}
            imageFile={this.state.imageFile}
            apolloClient={this.props.apolloClient}
        />
      </>
    );
  }
}

/*https://hackernoon.com/a-crash-course-on-serverless-with-aws-building-apis-with-lambda-and-aurora-serverless-49885c46e37a*/
