import React from 'react';
import parse from 'html-react-parser';

export default function AlertModal(props){
	return (
		<div className="alert-modal success">
			<div className="alert-modal-inner">
				<h3><i className={props && props.iconClass ? props.iconClass : 'far fa-exclamation-circle'} /> {props && props.title ? parse(props.title) : ''}</h3>
				{props && props.body ? parse(props.body) : ''}
			</div>
		</div>
	);
}
