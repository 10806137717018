import React, { Suspense, lazy } from 'react'
import Loading from "../partials/Loading";
const Login = lazy(() => import('../pages/Login'))

export default function AsyncLazyTour(props) {

    return (
        <Suspense fallback={<Loading />}>
            <Login apolloClient={props.apolloClient} />
        </Suspense>
    )
}
