import {config} from '../config';

const getData = async (queryEndPoint) => {
	return new Promise(async (resolve, reject) => {
		fetch(queryEndPoint, {
			method: 'GET',
			headers: {
				"Access-Control-Allow-Origin" : "*"
			}
		}).then(response => response.json()).then(data => {
			resolve(data);
		}).catch((error) => {
			reject(error);
		});
	});
}

/**
 * Get signed S3 url to upload files
 * @param {Object} data
 */
const getS3Url = async ({acl='public-read', file_name,file_type }) => {
	const data = {file_type,file_name, acl};
	return new Promise(async (resolve, reject) => {
		fetch(config.lambdaAPI + '/signed-url', {
			method: 'POST', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
				"Access-Control-Allow-Origin" : "*"
			},
			body: JSON.stringify(data),
		}).then(response => response.json()).then(data => {
			resolve(data);
		}).catch((error) => {
			reject(error);
		});
	});
}

/**
 * Get signed S3 url to upload files
 * @param {Object} data
 */

const uploadToS3 = async (data) => {
	const { signedRequest, file, fileType } = data;
	return new Promise(async (resolve, reject) => {
		fetch(file).then(res => res.blob()).then(fileBlob => {
			fetch(signedRequest, {
				method: 'PUT',
				headers: {
					"Access-Control-Allow-Origin" : "*",
					"Content-Type": fileType,
					"x-amz-acl": "public-read"
				},
				body: fileBlob // This is your file object
			}).then(response => {
				if(response.status === 200) {
					resolve(response);
				}
			}).catch(error => {
				reject(error);
			});

		})
	});
};

/**
 * Programmatically trigger resize event for window (DOM)
 */

const triggerWinResize = async () => {
	if (typeof(Event) === 'function') {
		// modern browsers
		window.dispatchEvent(new Event('resize'));
	} else {
		// for IE and other old browsers
		// causes deprecation warning on modern browsers
		var evt = window.document.createEvent('UIEvents');
		evt.initUIEvent('resize', true, false, window, 0);
		window.dispatchEvent(evt);
	}
}


/**
 * Get object with all values of form controls (input, textarea or select)
 * @param  {Element} form to get data object from
 */
const getFormData = async (form) => {
	const formData = new FormData(form);
	let values = {};
	formData.forEach((value, key) => {
		if (value) {
			if(value === 'true') value = true;
			if(value === 'false') value = false;
			values[key] = value;
		}
	});
	return values;
}

/**
 * Delete all of the nodes that equals the filter
 * @param  {NodeList} arrayOfElements list to filter from
 * @param  {Element} toExclude selector
 */
function filterOutNodes (arrayOfElements, toExclude) {
	return  Array.from(arrayOfElements).filter(function (elem) {
		return elem !== toExclude;
	});
}

/**
 * Slide the element up to hide it
 * @param  {Element} target to be toggled
 * @param  {Number} duration is the transition time in milliseconds
 */
function slideUp (target, duration=200) {
	target.style.transitionProperty = 'height, margin, padding';
	target.style.transitionDuration = duration + 'ms';
	target.style.boxSizing = 'border-box';
	target.style.height = target.offsetHeight + 'px';
	setTimeout( () => {
		target.style.overflow = 'hidden';
		target.style.height = 0;
		target.style.paddingTop = 0;
		target.style.paddingBottom = 0;
		target.style.marginTop = 0;
		target.style.marginBottom = 0;
		window.setTimeout( () => {
			target.style.display = 'none';
			target.style.removeProperty('height');
			target.style.removeProperty('padding-top');
			target.style.removeProperty('padding-bottom');
			target.style.removeProperty('margin-top');
			target.style.removeProperty('margin-bottom');
			target.style.removeProperty('overflow');
			target.style.removeProperty('transition-duration');
			target.style.removeProperty('transition-property');
			//alert("!");
		}, duration);
	}, 50);
}

/**
 * Slide the element down to show hidden element
 * @param  {Element} target to be toggled
 * @param  {Number} duration is the transition time in milliseconds
 */
function slideDown (target, duration= 200) {
	target.style.removeProperty('display');
	let display = window.getComputedStyle(target).display;
	if (display === 'none') display = 'block';
	target.style.display = display;
	let height = target.offsetHeight;
	target.style.overflow = 'hidden';
	target.style.height = 0;
	target.style.paddingTop = 0;
	target.style.paddingBottom = 0;
	target.style.marginTop = 0;
	target.style.marginBottom = 0;
	setTimeout(() => {
		target.style.boxSizing = 'border-box';
		target.style.transitionProperty = "height, margin, padding";
		target.style.transitionDuration = duration + 'ms';
		target.style.height = height + 'px';
		target.style.removeProperty('padding-top');
		target.style.removeProperty('padding-bottom');
		target.style.removeProperty('margin-top');
		target.style.removeProperty('margin-bottom');
		window.setTimeout( () => {
			target.style.removeProperty('height');
			target.style.removeProperty('overflow');
			target.style.removeProperty('transition-duration');
			target.style.removeProperty('transition-property');
		}, duration);
	},50);
}

/**
 * toggle show/hide the element
 * @param  {Element} target to be toggled
 * @param  {Number} duration is the transition time in milliseconds
 */
function slideToggle (target, duration = 200) {
	if (window.getComputedStyle(target).display === 'none') {
		return slideDown(target, duration);
	} else {
		return slideUp(target, duration);
	}
}



const syncBtnStatus = (status) => {
	const syncBtn = document.getElementById('btn-sync');
	if(syncBtn){
		syncBtn.classList.remove('loading');
		syncBtn.classList.remove('loaded');
		if(status !== 'reset'){
			syncBtn.classList.add(status);
		}
	}
}

const syncSeriesToDb = (apolloClient, mutation, seriesId) => {
	let data = localStorage.getItem(seriesId) ? JSON.parse(localStorage.getItem(seriesId)) : undefined;
	if(data) {
		syncBtnStatus('loading');
		if(data.Series_Era && data.Series_Era.length) {
			data.Series_Era = data.Series_Era.toString();
		} else {
			data.Series_Era = ""
		}
		if(data.Series_Tag && data.Series_Tag.length) {
			data.Series_Tag = data.Series_Tag.toString();
		} else {
			data.Series_Tag = ""
		}
		data.Series_lat = parseFloat(data.Series_lat);
		data.Series_lng = parseFloat(data.Series_lng);
		apolloClient.mutate({
			mutation: mutation,
			variables: {
				...data
			}
		}).then(result => {
			if(!result.loading){
				console.log('series synced');
				window.dispatchEvent(new CustomEvent('series_synced'));
				syncBtnStatus('loaded');
			}
		}).catch(e => {
			syncBtnStatus('loaded');
			console.log('Failed to sync series: ', e);
		});
	}
}

const syncFeatureToDb = (apolloClient, mutation, featureId) => {
	let data = localStorage.getItem(featureId) ? JSON.parse(localStorage.getItem(featureId)) : undefined;
	if(data && data.Feature_Name && data.Feature_Name.length) {
		syncBtnStatus('loading');
		if(data.Feature_Era && data.Feature_Era.length) {
			data.Feature_Era = data.Feature_Era.toString();
		} else {
			data.Feature_Era = ""
		}
		if(data.Feature_Tag && data.Feature_Tag.length) {
			data.Feature_Tag = data.Feature_Tag.toString();
		} else {
			data.Feature_Tag = ""
		}
		data.Feature_lat = parseFloat(data.Feature_lat);
		data.Feature_lng = parseFloat(data.Feature_lng);

		apolloClient.mutate({
			mutation: mutation,
			variables: {
				...data
			}
		}).then(result => {
			if(!result.loading){
				window.dispatchEvent(new CustomEvent('feature_synced'));
				console.log('feature synced');
				syncBtnStatus('loaded');
			}
		}).catch(e => {
			syncBtnStatus('loaded');
			console.log('Failed to sync feature: ', e);
		});
	}
}

const syncAssetToDb = (apolloClient, mutation, assetId) => {
	let data = localStorage.getItem(assetId) ? JSON.parse(localStorage.getItem(assetId)) : undefined;
	if(data) {
		syncBtnStatus('loading');
		apolloClient.mutate({
			mutation: mutation,
			variables: {
				...data
			}
		}).then(result => {
			if(!result.loading){
				console.log('asset synced');
				syncBtnStatus('loaded');
			}
		}).catch(e => {
			syncBtnStatus('loaded');
			console.log('Failed to sync asset: ', e);
		});
	}
}

export {
	getFormData,
	syncBtnStatus,
	syncSeriesToDb,
	syncFeatureToDb,
	syncAssetToDb,
	slideUp,
	slideDown,
	slideToggle,
	filterOutNodes,
	triggerWinResize,
	getS3Url,
	uploadToS3,
	getData
}
