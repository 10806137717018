import React from 'react';
import {validateInput} from '../../lib/validation';
import MultiStep from './MultiStep';
import MapBox from './MapBox';
import SeriesUploadImages from './SeriesUploadImages';
import EraAndTags from './EraAndTags';
import WriteScript from './WriteScript';
import {syncBtnStatus, syncFeatureToDb} from "../../lib/helpers";
import {featureMutation} from "../../graphQL/mutation/feature.mutation";
import {config} from "../../config";


export default class SeriesFeatures extends React.Component {


	constructor(props) {
		super(props);
		this.state = {
			Feature_Name: '' ,
			Feature_Asset_Text: '',
			AssetsIdList: [],
			Feature_BoxImg: '',
			Feature_BoxImgCap : '',
			Feature_MapZoom: this.props.Series_MapZoom,
			Feature_lng : this.props.Series_lng,
			Feature_lat : this.props.Series_lat,
			Feature_geo: '',
			Feature_Tag: [],
			Feature_Era: [],
			HasMap: true,
			steps: undefined,
			Series: this.props._id,
			User: this.props.viewer._id,
			Feature_Index: this.props.index ? this.props.index : 0,
			checkpoint: 0,
			featureSyncTimer: undefined,
			assetSyncTimer: undefined,
		}
	}

	componentDidMount() {
		this.prepSteps();

		let feature = JSON.parse(localStorage.getItem(this.props.featureId));

		if(feature.AssetsIdList && feature.AssetsIdList.length){
			const asset = JSON.parse(localStorage.getItem(feature.AssetsIdList));
			feature.Feature_Asset_Text = asset.Asset_Text;
		}

		this.setState({
			...feature
		});

		window.addEventListener('stepSkipped', async (e) => {
			console.log('skipped');
			await this.setStateAsync({
				HasMap: false
			});
			this.prepSteps();
			document.querySelector('.js-btn-next').click();
			this.updateFeature();
		})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.state.HasMap !== prevState.HasMap){
			this.prepSteps();
		}
	}


	validateInput = async (e) => {
		await validateInput(e.currentTarget);
	}

	setStateAsync(state) {
		return new Promise((resolve) => {
		  this.setState(state, resolve)
		});
	}

	onChange = e => {
		this.setState({
			[e.target.name] : e.target.value
		});
	}

	setMap = async data => {
		await this.setStateAsync({
			Feature_lat: data.lat,
			Feature_lng: data.lng,
			Feature_geo: data.geo,
			Feature_MapZoom: data.zoom,
			checkpoint: 0
		});
		this.updateFeature();
	}

	setFeatureImage = async img => {
		let imageData = {};
		if(img && img.imageUrl){
			imageData.Feature_BoxImg = img.imageUrl
		}
		if(img && img.imageCaption){
			imageData.Feature_BoxImgCap = img.imageCaption
		}
		await this.setStateAsync({
			...imageData,
			checkpoint: 1
		});
		this.updateFeature();	
	}

	setTags = async data => {
		let tagsArray = [], erasArray = [];
		data.tags.forEach(tag => {
			tagsArray.push(tag.id);
		});
		data.eras.forEach(era => {
			erasArray.push(era.id);
		});
		await this.setStateAsync({
			Feature_Tag: tagsArray,
			Feature_Era: erasArray,
			checkpoint: 2
		});
		this.updateFeature();
	}

	setScript = async data => {
		/*todo: Add logic to make assets out of feature scripts*/
		await this.setStateAsync({
			Feature_Asset_Text: data.script,
			checkpoint: 3
		});
		this.updateFeature();
	}


	prepFeatureToDb = () => {
		clearTimeout(this.state.featureSyncTimer);
		this.state.featureSyncTimer = setTimeout(() => {
			syncFeatureToDb(this.props.apolloClient, featureMutation, this.props.featureId);
		}, config.dbSyncDelay);
		syncBtnStatus('reset');
	}

	updateFeature = () => {
		localStorage.setItem(this.props.featureId, JSON.stringify(this.state));
		setTimeout(() => {
			this.prepFeatureToDb();
		}, 100);
	}



	onFeatureComplete = data => {
		this.props.onComplete({
			canAddFeature: true,
			activeChapter: this.props.features ? this.props.features.length + 1 : 1
		});
	}

	prepSteps = () => {
		let steps;
		const drawOptions = {
			polyline: true,
			polygon: false,
			circlemarker: false ,
			circle: false, // Turns off this drawing tool
			rectangle: false ,
			marker: false
		}

		if(this.state.HasMap){
			steps = [
				{
					name: 'Draw the path',
					allowSkip: true,
					content: <MapBox map="featureMap" description='Use the "path" tool to place points on the map. Hit the last point twice to finish. ' id="feature-area" draw={drawOptions} zoom={this.state.Feature_MapZoom} lat={this.state.Feature_lat} lng={this.state.Feature_lng} geo={this.state.Feature_geo}  callback={this.setMap} />,
					defaultError: "Please use tool tools to draw an outline on the map."
				},  {
					name: 'Image',
					content: <SeriesUploadImages defaultImage={this.state.Feature_BoxImg} imageCaption={this.state.Feature_BoxImgCap} wikiID={this.props.Series_wikiID} id="feature-image" callback={this.setFeatureImage} />,
					defaultError: "Please select an image, or upload a new one and enter a caption for the choosen image."
				}, {
					name: 'Tags',
					content: <EraAndTags id="series-eras-tags" tags={this.state.Feature_Tag} eras={this.state.Feature_Era} callback={this.setTags} />,
					defaultError: "Please enter tags"
				}, {
					name: 'Script',
					content: <WriteScript title={this.state.Feature_Name} script={this.state.Feature_Asset_Text} callback={this.setScript} />,
					defaultError: "Please write your script."
				}
			]
		} else if(!this.state.HasMap) {
			steps = [
				{
					name: 'Draw the path',
					note: '<span class="color-danger d-block smaller">You\'ve skipped this.</span>',
					content: <div>
						<p>You have chose to skip adding map.</p>
						<button className="btn btn-default" onClick={e => {
							this.setStateAsync({
								HasMap: true
							}).then(() => {
								this.updateFeature();
							});
						}}>Click here to add map.</button>
					</div>,
					defaultError: "Please use tool tools to draw an outline on the map."
				},{
					name: 'Script',
					content: <WriteScript title={this.state.Feature_Name} script={this.state.Feature_Asset_Text} callback={this.setScript} />,
					defaultError: "Please write your script."
				}
			];
		}
		this.setState({
			steps: steps
		});
	}

	render() {



		const onComplete ={
			button: 'Done',
			callback: this.onFeatureComplete
		}

		return (
			<>
				{this.state.steps && <MultiStep id="feature-multistep" onComplete={onComplete} validate="true" startAt={this.state.checkpoint ? this.state.checkpoint : 0 } steps={this.state.steps}/>}
			</>

		)
	}

}

/*https://codepen.io/nat-davydova/pen/PoYXZxg*/
