import React, {Suspense, useState} from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { gql } from "@apollo/client";

import "./sass/style.scss";
import Signup from "./components/pages/Signup";
import ForgotPassword from "./components/pages/ForgotPassword";
import Profile from "./components/pages/Profile";
import Logout from "./components/pages/Logout";
import CookieDisclosures from "./components/pages/CookieDisclosures";
import AsyncLazyLogin from "./components/lazy/AsyncLazyLogin";
import Dashboard from "./components/pages/Dashboard";
import Review from "./components/pages/review";
import ExistingTours from "./components/pages/ExistingTours";
import {getViewer} from "./lib/getViewer";
import Loading from "./components/partials/Loading";
import SeriesPage from "./components/pages/SeriesPage";


const App = (props) => {

  const [viewer, setViewer] = useState();
  const [isLoading, setIsLoading] = useState(true)

  getViewer(props.apolloClient).then(viewer => {
    console.log('authenticated');
    setViewer(viewer);
    setIsLoading(false);
    if(window.location.pathname === '/') {
      window.location = '/dashboard';
    }
  }).catch(e => {
    setIsLoading(false);
    if(window.location.pathname !== '/') {
      window.location = '/';
    }
  });

  const openRoutes = <Switch>
    <Route
        exact
        path="/"
        render={() => <AsyncLazyLogin apolloClient={props.apolloClient} />}
    />
    <Route
        exact
        path="/register"
        render={() => <Signup apolloClient={props.apolloClient} />}
    />
    <Route
        exact
        path="/forgot-password"
        render={() => <ForgotPassword />}
    />
    <Route
        exact
        path="/cookies"
        render={(value) => (
            <CookieDisclosures
                prevPath={
                  !value.location.state ? "/" : value.location.state.prevPath
                }
            />
        )}
    />
  </Switch>;

  const protectedRoutes = <Switch>
    <Route
        exact
        path="/dashboard"
        render={() => <Dashboard viewer={viewer} apolloClient={props.apolloClient} />}
    />
    <Route
        exact
        path="/profile"
        render={() => <Profile viewer={viewer} apolloClient={props.apolloClient} />}
    />
    <Route
        exact
        path="/logout"
        render={() => <Logout viewer={viewer} apolloClient={props.apolloClient} />}
    />
    <Route
        exact
        path="/tour"
        render={() => <SeriesPage viewer={viewer} apolloClient={props.apolloClient} newTour={true}/>}
    />
    <Route
        exact
        path="/tours"
        render={() => (
            <ExistingTours viewer={viewer} apolloClient={props.apolloClient} />
        )}
    />
    <Route
        exact
        path="/review"
        render={() => <Review viewer={viewer} apolloClient={props.apolloClient} />}
    />
  </Switch>

  if(isLoading) {
    return <Loading />
  }
  return (
    <>
      <main className="page gradient-bg">
        <div className="container">
          {viewer && !isLoading ? <Router>
                {openRoutes}
                {protectedRoutes}
          </Router> :
          /*Not logged in*/
              <Router>
                {openRoutes}
              </Router>
          }

        </div>
      </main>

    </>
  );
};

export default App;
