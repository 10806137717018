import React from 'react';
import {validateInput} from '../../lib/validation';
import MultiStep from './MultiStep';
import MapBox from './MapBox';
import WriteScript from './WriteScript';


export default class FinalizeSeries extends React.Component {

	validateInput = async (e) => {
		await validateInput(e.currentTarget);
	}

	setSeriesMap = async data => {
		this.props.callback({
			Series_geo: data.geo,
			finalCheckpoint: 0
		})
	}

	setScript = async data => {
		this.props.callback({
			Series_Summary : data.script,
			finalCheckpoint: 1
		})
	}
	
	setTeaser = async data => {
		console.log(data);
		this.props.callback({
			Series_Teaser : data.script,
			finalCheckpoint: 2
		})
	}

	onSeriesComplete = () => {
		this.props.callback({
			canAddFeature: true,
			finalCheckpoint: 2,
			activeChapter: 1001,
			showSummary: true,
			Status: 'review'
		});
		window.addEventListener('series_synced', (e)=> {
			window.location.href = "/tours";
		});
	}



	render() {

		const centerPointMapDrawOptions = {
			polyline: false,
			polygon: true,
			circlemarker: false ,
			circle: false, // Turns off this drawing tool
			rectangle: false ,
			marker: false
		}
		const tourSettingsSteps = [
			{
				name: 'Pologon Area',
				content: <MapBox map="seriesMap" id="polygon-area" draw={centerPointMapDrawOptions} lat={this.props.Series_lat} lng={this.props.Series_lng} geo={this.props.Series_geo}  zoom={this.props.Series_MapZoom} callback={this.setSeriesMap} />,
				defaultError: "Please click the marker icon and click a location on the map that is near the features your tour is about. This will create center point so that you may easily add additional map points on your tour."
			}, {
				name: 'Script',
				content: <WriteScript title={this.props.Series_Name} script={this.props.Series_Summary} callback={this.setScript} />,
				defaultError: "Please write your script."
			}, {
				name: 'Teaser',
				content: <WriteScript title={this.props.Series_Name} id="teaserScript" script={this.props.Series_Teaser} callback={this.setTeaser} />,
				defaultError: "Please write a teaser of max 50 words."
			}
		];

		const onComplete = {
			button: 'Submit for review',
			callback: this.onSeriesComplete
		}

		return (
			<>
				<MultiStep id="finalize-multistep" onComplete={onComplete} validate="true" startAt={this.props.finalCheckpoint ? this.props.finalCheckpoint : 0 } steps={tourSettingsSteps}/>
			</>

		)
	}

}

/*https://codepen.io/nat-davydova/pen/PoYXZxg*/
