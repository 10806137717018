import 'cross-fetch/polyfill';
import {gql} from "@apollo/client";

const viewerQuery = gql`
  query viewer{
    me{
        _id
        User_Username
        User_FirstName
        User_LanguageID
        User_LastName
        User_Name
        User_Active
        User_Pronounce
        User_Photo
        User_Level
        User_Venmo
        User_Mobile
        User_Email
        User_NickName
        User_Bio
        User_Location
        User_Email_verified
        SeriesCount
    }
  }
`;

const getViewer =  async (apolloClient) => {
	return new Promise(async (resolve,reject) => {
		try {
			const viewerRes = await apolloClient.query({
				query: viewerQuery
			});
			resolve(viewerRes.data.me);
		}catch (e) {
			reject(null)
		}
	});
}



export {
	getViewer,
	viewerQuery
}
