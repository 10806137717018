import React from 'react';


export default class WriteScript extends React.Component{

	constructor(props) {
		super(props);
		this.state = {
			script: ''
		}
	}

	componentDidMount() {
		if(this.props.id){
			document.getElementById(this.props.id).value = this.props.script;
		} else{
			document.getElementById('scriptText').value = this.props.script;
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if(this.props.script !== prevProps.script){
			if(this.props.id){
				document.getElementById(this.props.id).value = this.props.script;
			} else{
				document.getElementById('scriptText').value = this.props.script;
			}
		}
	}

	setStateAsync(state) {
		return new Promise((resolve) => {
		  this.setState(state, resolve)
		});
	}

	callback = () => {
		if(this.props.callback){
			this.props.callback({
				script: this.props.id ? document.getElementById(this.props.id).value : document.getElementById('scriptText').value
			});
		}
	}

	render() {
		return(
			<div id="write-script-wrap">
				<div id="write-script">
					{/*<label className="text-muted" htmlFor={this.props.id ? this.props.id : "scriptText"}>Script of your guide for {this.props.title? this.props.title:"XYZ"}</label>*/}
					<div className="form-group floating-label-group">
						<textarea className="form-control" 
								onChange={this.callback}
						          name="script"
						          id={this.props.id ? this.props.id : "scriptText"}
						          placeholder="Script"
						          required
						/>
						<div className="after-control">
							<span className="focus-effect"/>
							<div className="popover bs-popover-top">
								<div className="arrow" />
								<div className="popover-body">
									<span className="no-wrap">Script is required</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		)
	}

}
