import React from 'react';
import {validateInput} from '../../lib/validation';
import MultiStep from './MultiStep';
import MapBox from './MapBox';
import WikiPages from './WikiPages';
import SeriesUploadImages from './SeriesUploadImages';
import EraAndTags from './EraAndTags';


export default class Series extends React.Component {

	validateInput = async (e) => {
		await validateInput(e.currentTarget);
	}


	setSeriesMap = async data => {
		console.log(data);
		this.props.callback({
			Series_wikiID: '',
			Series_Initial_geo: data.geo,
			Series_lat: data.lat,
			Series_lng: data.lng,
			Series_MapZoom: data.zoom,
			checkpoint: 0
		})
	}

	setSeriesWiki = data => {
		this.props.callback({
			Series_wikiID: data.wikiID,
			checkpoint: 1
		})
	}

	setSeriesImage = async img => {
		let imageData = {};
		if(img && img.imageUrl){
			imageData.Series_BoxImg = img.imageUrl
		}
		if(img && img.imageCaption){
			imageData.Series_BoxImgCap = img.imageCaption
		}
		this.props.callback({
			...imageData,
			checkpoint: 2
		});
	}

	setSeriesTags = async data => {
		let tagsArray = [], erasArray = [];
		data.tags.forEach(tag => {
			tagsArray.push(tag.id);
		});
		data.eras.forEach(era => {
			erasArray.push(era.id);
		});
		this.props.callback({
			Series_Era: erasArray,
			Series_Tag: tagsArray,
			checkpoint: 3
		});
	}

	onSeriesComplete = data => {
		this.props.callback({
			canAddFeature: true,
			checkpoint: 3,
			activeChapter: 1
		});
	}

	render() {

		const centerPointMapDrawOptions = {
			polyline: false,
			polygon: false,
			circlemarker: false ,
			circle: false, // Turns off this drawing tool
			rectangle: false ,
			marker: true
		}
		const tourSettingsSteps = [
			{
				name: 'Center Point',
				content: <MapBox map="seriesMap" id="center-point" draw={centerPointMapDrawOptions} lat={this.props.Series_lat} lng={this.props.Series_lng} geo={this.props.Series_Initial_geo} zoom={this.props.Series_MapZoom}  callback={this.setSeriesMap} />,
				defaultError: "Please click the marker icon and click a location on the map that is near the features your tour is about. This will create center point so that you may easily add additional map points on your tour."
			}, {
				name: 'Wiki Connection',
				content: <WikiPages id="wiki-connection" lat={this.props.Series_lat} lng={this.props.Series_lng} wikiID={this.props.Series_wikiID} callback={this.setSeriesWiki} />,
				defaultError: "Please select one of the following wikipedia articles that is closest related to your tour."
			}, {
				name: 'Image',
				content: <SeriesUploadImages defaultImage={this.props.Series_BoxImg} imageCaption={this.props.Series_BoxImgCap} wikiID={this.props.Series_wikiID} id="series-image" callback={this.setSeriesImage} />,
				defaultError: "Please select an image, or upload a new one."
			}, {
				name: 'Tags',
				content: <EraAndTags id="series-eras-tags" tags={this.props.Series_Tag} eras={this.props.Series_Era} callback={this.setSeriesTags} />,
				defaultError: "Please enter tags"
			}
		];

		const onComplete ={
			button: 'Save Settings',
			callback: this.onSeriesComplete
		}

		return (
			<>
				<MultiStep id="series-multistep" onComplete={onComplete} validate="true" startAt={this.props.checkpoint ? this.props.checkpoint : 0 } steps={tourSettingsSteps}/>
			</>

		)
	}

}

/*https://codepen.io/nat-davydova/pen/PoYXZxg*/
