import {validateForm} from './validation';

const submitHandler = async (e, mutationHandler, extraData, isElement=false) => {
	console.log('aa')
	e.preventDefault();
	let selectedForm;
	if(isElement) {
		selectedForm = e;
	} else {
		e.preventDefault();
		selectedForm = e.currentTarget;
	}
	const hasErrors = await validateForm(selectedForm);
	if(!hasErrors) {
		selectedForm.classList.add('loading');
		const formData = new FormData(selectedForm);
		let values = {};
		formData.forEach((value, key) => {
			if (value) {
				if(value === 'on'){
					values[key] = true;
				}
				else{
					values[key] = value;
				}
			}
		});
		if(extraData) {
			values = {...values, ...extraData}
		}
		await mutationHandler({variables: values});
		setTimeout(()=> {
			selectedForm.classList.remove('loading');
		}, 100);
	} else{
		console.log('Form validation failed.');
	}
};

export default submitHandler;