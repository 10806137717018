import {gql} from "@apollo/client";

const seriesMutation = gql`
mutation seriesMutation(
	$_id: ID,
	$Status: String!,
	$activeChapter: Int,
	$Series_Name: String!,
	$Series_UUID: String,
	$Series_JSON: String,
	$Series_Active: String,
	$Series_Summary: String,
	$Series_Teaser: String,
	$Series_Img: String,
	$Series_Era: String,
	$Series_Tag: String,
	$Series_lng: Float,
	$Series_lat: Float,
	$Series_MapZoom: Int,
	$Series_elv: Float,
	$Series_mph: Float,
	$Series_deg: Float,
	$Series_geo: String,
	$Series_Initial_geo: String,
	$Series_exp: Date,
	$Series_wikiID: String,
	$Series_googID: String,
	$Series_BoxImg: String,
	$Series_BoxImgUrl:String,
	$Series_BoxImgCap: String,
	$Series_Sequenced: Int,
	$User: ID!
) {
	series(
            _id: $_id,
            Status: $Status,
            activeChapter: $activeChapter,
            Series_Name: $Series_Name 
            Series_UUID: $Series_UUID,
            Series_JSON: $Series_JSON,
            Series_Active: $Series_Active,
            Series_Summary: $Series_Summary,
            Series_Teaser: $Series_Teaser,
            Series_Img: $Series_Img,
            Series_Era: $Series_Era,
            Series_Tag: $Series_Tag,
            Series_MapZoom: $Series_MapZoom,
            Series_lng: $Series_lng,
            Series_lat: $Series_lat,
            Series_elv: $Series_elv,
            Series_mph: $Series_mph,
            Series_deg: $Series_deg,
            Series_geo: $Series_geo,
            Series_Initial_geo: $Series_Initial_geo,
            Series_exp: $Series_exp,
            Series_wikiID: $Series_wikiID,
            Series_googID: $Series_googID,
            Series_BoxImg: $Series_BoxImg,
            Series_BoxImgUrl: $Series_BoxImgUrl,
            Series_BoxImgCap: $Series_BoxImgCap,
            Series_Sequenced: $Series_Sequenced,
            User: $User
        ) {
            _id
            Status
        }	
}
`;


export {
    seriesMutation
}