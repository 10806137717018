import {gql} from "@apollo/client";

const featureMutation = gql`
mutation featureMutation(
        $_id: ID, 
        $Feature_Sequence: Int,
        $Feature_Name: String!, 
        $Feature_UUID: String, 
        $Feature_JSON: String, 
        $Feature_Summary: String,
        $Feature_Img: String, 
        $Feature_Era: String, 
        $Feature_Tag: String, 
        $Feature_MapZoom: Int,
        $Feature_lng: Float,
        $Feature_lat: Float, 
        $Feature_elv: Float, 
        $Feature_mph: Float, 
        $Feature_deg: Float, 
        $Feature_geo: String, 
        $Feature_exp: Date, 
        $Feature_wikiID: String, 
        $Feature_googID: String, 
        $Feature_BoxImg: String, 
        $Feature_BoxImgUrl: String, 
        $Feature_BoxImgCap: String, 
        $Feature_Active: Int, 
        $HasMap: Boolean, 
        $Series: ID, 
        $User: ID
    ) {
        feature(
            _id: $_id, 
            Feature_Sequence: $Feature_Sequence,
            Feature_Name: $Feature_Name, 
            Feature_UUID: $Feature_UUID, 
            Feature_JSON: $Feature_JSON, 
            Feature_Summary: $Feature_Summary,
            Feature_Img: $Feature_Img, 
            Feature_Era: $Feature_Era, 
            Feature_Tag: $Feature_Tag, 
            Feature_MapZoom: $Feature_MapZoom,
            Feature_lng: $Feature_lng,
            Feature_lat: $Feature_lat, 
            Feature_elv: $Feature_elv, 
            Feature_mph: $Feature_mph, 
            Feature_deg: $Feature_deg, 
            Feature_geo: $Feature_geo, 
            Feature_exp: $Feature_exp, 
            Feature_wikiID: $Feature_wikiID, 
            Feature_googID: $Feature_googID, 
            Feature_BoxImg: $Feature_BoxImg, 
            Feature_BoxImgUrl: $Feature_BoxImgUrl, 
            Feature_BoxImgCap: $Feature_BoxImgCap, 
            Feature_Active: $Feature_Active, 
            HasMap: $HasMap, 
            Series: $Series, 
            User: $User
        ) {
            _id
            Feature_Name
        }
    }
`;

export {
    featureMutation
}