import React from 'react';
import {getS3Url, triggerWinResize, uploadToS3} from '../../lib/helpers';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import { v1 as uuidv1 } from 'uuid';
import {config} from '../../config';
import {validateInput} from '../../lib/validation';

export default class SeriesUploadImages extends React.Component{

	constructor(props) {
		super(props);
		this.state = {
			imagesPulled: false,
			wikiImagesRes: [],
			imageSelection: 0,
			componentUI :  '',
			hasCropped: false,
			selectedImageDataUrl: undefined,
			selectedImageType: undefined,
			wikiImages: [],
			nextBtn: undefined,
			cropper: undefined,
			imageCaption: '',
			imageUrl: undefined,
		}
	}

	validateInput = async (e) => {
		await validateInput(e.currentTarget);
	}

	componentDidUpdate= async (prevProps, prevState, snapshot) => {
		if(this.props.wikiID && prevProps.wikiID !== this.props.wikiID && this.props.wikiID.length > 1) {
			this.getWikiImagesWithUrl();
		}
		if(this.props.imageCaption && prevProps.imageCaption !== this.props.imageCaption && this.props.imageCaption.length > 1) {
			this.setState({
				imageCaption: this.props.imageCaption
			});
		}
		if(this.props.defaultImage && prevProps.defaultImage !== this.props.defaultImage && this.props.defaultImage.length > 1) {
			this.setState({
				imageUrl: this.props.defaultImage,
				imageSelection: 2
			});
			const stepWrap = document.getElementById(this.props['id'] ? this.props['id'] : "images-upload").closest('.multisteps-component__panel');
			stepWrap.querySelector('.js-btn-next').classList.remove('invisible');
		}
	}

	componentDidMount = async () => {
		const parentID = this.props['id'] ? this.props['id'] : "images-upload",
			parentEle = document.getElementById(parentID);
		if(this.props.defaultImage && this.props.defaultImage.length > 1){
			await this.setState({
				imageUrl: this.props.defaultImage,
				imageSelection: 2
			});
			if(this.props.imageCaption) {
				this.setState({
					imageCaption: this.props.imageCaption
				});
			}
		} else{
			if(parentEle){
				const stepWrap = parentEle.closest('.multisteps-component__panel');
				stepWrap.querySelector('.js-btn-next').classList.add('invisible');
			}
		}


		if(this.props.wikiID && this.props.wikiID.length > 1) {
			this.getWikiImagesWithUrl();
		}
	}

	getWikiImagesWithUrl = async () => {
		if(this.props.wikiID) {
			const wikiSelectedPage = this.props.wikiID;
			const wikiQueryUrl = 'https://en.wikipedia.org//w/api.php?action=query&origin=*&format=json&prop=images&pageids=' + wikiSelectedPage;
			fetch(wikiQueryUrl).then(response => response.json()).then(async data => {
				await this.setState({
					wikiImagesRes: data['query']['pages'][wikiSelectedPage]['images']
				});
				setTimeout(() => {
					this.fetchWikiImageInfo();
				}, 150);
			}).catch((error) => {
				console.error('Error:', error);
			});
		}
	}

	fetchWikiImageInfo = async () => {
		if(this.state.wikiImagesRes){
			for (const img of this.state.wikiImagesRes) {

				const excludeImages = ['ui icon', 'logo', 'icon.svg', 'map of', 'map.svg', 'pog.svg', 'flag of'];
				let isValidImage = true;

				for(let exclude of excludeImages){
					if((img.title.toLowerCase()).includes(exclude)){
						isValidImage = false;
					}
				}
				if(isValidImage){
					const queryUrl = 'https://en.wikipedia.org/w/api.php?action=query&origin=*&format=json&prop=imageinfo&iiprop=url&titles=' + img.title;
					fetch(queryUrl).then(response => response.json()).then(async data => {
						await this.setState({
							wikiImages: [{
								title: img.title,
								url: Object.entries(data['query']['pages'])[0][1]['imageinfo'][0]['url']
							}, ...this.state.wikiImages]
						})
					}).catch((error) => {
						console.warn('Error:', error);
					});
				}
			}
		}
	}

	onChange = async e => {
		const parentEle = document.getElementById(this.props['id'] ? this.props['id'] : "images-upload");
		const selectedWikiImageIndex = parentEle.querySelector('input[name="selectedImage"]:checked').value;
		const url = this.state.wikiImages[selectedWikiImageIndex].url;
		const jpgTypes = ['jpg', 'JPG', 'jpeg', 'JPEG'];
		const imageType = jpgTypes.indexOf(url.split('.').pop()) !== -1 ? 'image/jpeg' : 'image/' + url.split('.').pop();
		await this.setState({
			imageSelection: 1,
			selectedImageType: imageType
		});
		fetch(url).then(res => res.blob()).then(blob => {
			this.displayImage(blob, parentEle.querySelector('#selectedImgWrap'));
		});
	}

	onImageUpload = async e => {
		const uploadedImageFile = e.currentTarget.files[0];
		await this.setState({
			imageSelection: 1,
			selectedImageType: uploadedImageFile.type
		});
		this.displayImage(uploadedImageFile,document.getElementById(this.props['id'] ? this.props['id'] : "images-upload").querySelector('#selectedImgWrap'));
	}

	displayImage = async (image, ele) => {
		let objectURL = URL.createObjectURL(image);
		let img = new Image();
		img.src = objectURL;
		img.onload = this.onSelectedImageLoad;
		img.id = 'selectedImg';
		ele.appendChild(img);
	}

	onSelectedImageLoad = (e) => {
		e.currentTarget.parentElement.classList.remove('loading');
		triggerWinResize();
		this.initCropper();
	}

	initCropper = () => {
		const el = document.getElementById(this.props['id'] ? this.props['id'] : "images-upload").querySelector('#selectedImg');
		const cropper = new Cropper(el, {aspectRatio: 1});
		this.setState({
			cropper: cropper
		})
	}

	cropAndUpload = async (e) => {
		const btn = e.currentTarget;
		btn.classList.add('loading');
		const croppedBase64Data = this.state.cropper.getCroppedCanvas().toDataURL(this.state.selectedImageType);
		await this.setState({
			hasCropped: true,
			selectedImageDataUrl: croppedBase64Data
		});
		await this.displayCroppedImage();
		// btn.classList.remove('loading');
		await this.uploadSelectedImage(btn);

	}

	displayCroppedImage = async () => {
		this.state.cropper.destroy();
		document.getElementById('selectedImg').remove();
		const appendTo = document.getElementById(this.props['id'] ? this.props['id'] : "images-upload").querySelector('#selectedImgWrap');
		let img = new Image();
		img.src = this.state.selectedImageDataUrl;
		img.id = 'croppedImg';
		appendTo.appendChild(img);
		setTimeout(() => {
			triggerWinResize();
		}, 250);
	}

	uploadSelectedImage = async (btn) => {
		const fileName = uuidv1();
		try {
			const s3SignedData = await getS3Url({
				file_name: fileName,
				file_type: this.state.selectedImageType
			});
			const s3Response = await uploadToS3({
				...s3SignedData,
				file: this.state.selectedImageDataUrl,
				fileType: this.state.selectedImageType
			});
			if(s3Response.status === 200) {
				document.getElementById(this.props['id'] ? this.props['id'] : "images-upload").querySelector('.s3Image').value = s3SignedData.url;
				await this.setState({
					imageUrl: s3SignedData.url
				});
				const stepWrap = document.getElementById(this.props['id'] ? this.props['id'] : "images-upload").closest('.multisteps-component__panel');
				const nextBtn = stepWrap.querySelector('.js-btn-next');
				setTimeout(() => {
					nextBtn.classList.remove('invisible');
				}, 500);
				if(this.props.callback) {
					this.props.callback({
						imageUrl: this.state.imageUrl
					});
				}
			}
			btn.classList.remove('loading');
		} catch (e) {
			console.warn(e);
		}
	}

	onCaptionChange = async (e) => {
		this.setState({
			imageCaption: e.currentTarget.value
		});
	}

	updateCaption = () => {
		if(this.props.callback) {
			this.props.callback({
				imageCaption: document.getElementById('BoxImgCap').value
			});
		}
	}


	render() {
		let imageSelectionUi = ''
		if(this.state.imageSelection === 1) {
			imageSelectionUi = (<div className="animated fadeInUp position-relative">
				<button className="btn btn-wide btn-secondary ml-auto pseudo-next" onClick={this.cropAndUpload}>
					<span className="btn-text">Crop and Upload</span>
					<span className="spinner">
						<div className="bounce1" />
						<div className="bounce2" />
						<div className="bounce3" />
					</span>
				</button>
				<div className="row">
					<div className="col-12 col-md-4">
						<button onClick={() => {this.setState({imageSelection: 0, hasCropped: false})}} className="btn color-primary p-0 mb-3"><i className="fal fa-arrow-left" /> Change Image</button>
					</div>
				</div>
				<div className="image-viewport" id="selectedImgWrap" />
			</div>);
		} else if(this.state.imageSelection === 0) {
			imageSelectionUi = (
				<div>
					<div className="mb-3">

						<p className="d-inline-block animated fadeInUp position-relative">

							{this.state.wikiImages.length > 0 ? 'Select one of the following images or' : ''}
						</p>
						<div className="d-inline-block file-selector-wrap">
							<input onChange={this.onImageUpload} id="uploadNewImage" name="uploadNewImage" type="file" accept=".jpg, .jpeg, .png" />
							<label htmlFor="uploadNewImage" className="btn text-400 color-primary"><i className="fas fa-cloud-upload" /> Upload a new image</label>
						</div>
					</div>
					<div className="row">
						{
							this.state.wikiImages.map((img, i) => {
								return (
									<div key={'wiki-image-' + i} className="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
										<div className="form-group custom-image-selector" style={{backgroundImage: `url('${img.url}')`}}>
											<img onLoad={triggerWinResize} src={img.url} alt={img.title} />
											<label htmlFor={"wiki-page-" + i}>{img.title.replace('File:', '')}</label>
											<input type="radio"
											       onChange={this.onChange}
											       value={i}
											       className="form-control"
											       name="selectedImage"
											       title={img.title.replace('File:', '')}
											       required
											/>
											<div className="after-control">
												<span className="focus-effect"/>
											</div>
										</div>
									</div>
								)
							})
						}
					</div>
				</div>)
		} else if(this.state.imageSelection === 2 && this.props.defaultImage && this.props.defaultImage.length > 0) {
			imageSelectionUi = (
				<>
					<div className="pt-3">
						<div className="row">
							<div className="col-12 col-md-4">
								<button onClick={() => {this.setState({imageSelection: 0, hasCropped: false})}} className="btn color-primary p-0 mb-3"><i className="fal fa-arrow-left" /> Change Image</button>
							</div>
							<div className="col-12 col-md-8">
								<div className="form-group floating-label-group">
									<input className="form-control"
											type="text"
											name="BoxImgCap"
											id="BoxImgCap"
											placeholder="Image caption"
											data-placement="top"
											data-trigger="hover"
											onBlur={this.updateCaption}
											value={this.state.imageCaption}
											onChange={this.onCaptionChange}
											required />
									<div className="after-control">
										<label className="floating-label" htmlFor="BoxImgCap">Image Caption</label>
										<div className="popover bs-popover-top">
											<div className="arrow" />
											<div className="popover-body">
												<span className="no-wrap">Use this space to describe the image, add copyrights,<br/> give credit or specify the source</span>
											</div>
										</div>
										<span className="focus-effect" />
									</div>
								</div>
							</div>
						</div>

						<img src={config.s3Bucket + this.props.defaultImage} alt="default"/>
					</div>
				</>
			)
		}
		return(
			<div id={this.props['id'] ? this.props['id'] + '-wrap' : "images-upload-wrap"}>
				<div id={this.props['id'] ? this.props['id'] : "images-upload"} className="images-upload-lists">
					<input type="hidden" value={this.props.defaultImage ? this.props.defaultImage: '' } className="s3Image form-control" name="s3Image" required/>
					{imageSelectionUi}
				</div>
			</div>
		)
	}

}


/*
Resource: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
* */
