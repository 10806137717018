import {gql} from "@apollo/client";

const seriesQuery = gql`
    query seriesQuery ($userId: ID, $Status: String){
        series(userId: $userId, Status: $Status) {
            _id
            Series_Name
            Status
            Features {
                _id
                Feature_Name
            }
        }
    }
`;

const oneSeriesQuery = gql`
    query seriesQuery ($_id: ID!){
        seriesOne(_id: $_id) {
            _id
            Series_Name
            Status
            activeChapter
            Series_Name
            Series_UUID
            Series_JSON
            Series_Active
            Series_Summary
            Series_Teaser
            Series_Img
            Series_Era
            Series_Tag
            Series_MapZoom
            Series_lng
            Series_lat
            Series_elv
            Series_mph
            Series_deg
            Series_geo
            Series_Initial_geo
            Series_exp
            Series_wikiID
            Series_googID
            Series_BoxImg
            Series_BoxImgUrl
            Series_BoxImgCap
            Series_Sequenced
            User {
                _id
            }
            FeaturesIdList
            Features {
                _id
                Feature_Name
                Feature_UUID
                Feature_JSON
                Feature_Summary
                Feature_Img
                Feature_Era
                Feature_Tag
                Feature_lng
                Feature_MapZoom
                Feature_lat
                Feature_elv
                Feature_mph
                Feature_deg
                Feature_geo
                Feature_exp
                Feature_wikiID
                Feature_googID
                Feature_BoxImg
                Feature_BoxImgUrl
                Feature_BoxImgCap
                Feature_Active
                HasMap
                AssetsIdList
                Assets {
                    _id
                    Asset_Text
                    Asset_Active
                }
            }
        }
    }
`;

export {
    seriesQuery,
    oneSeriesQuery
}