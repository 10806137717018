import React from 'react';
import { JsonView } from 'json-view-for-react';
import {getViewer} from "../../lib/getViewer";

export default class Review extends React.Component{

	constructor(props) {
		super(props);
		this.state = {
			tourToReview: undefined,
			features : []
		}
	}

	componentDidMount = async () => {

		const tour = (new URLSearchParams(window.location.search)).get("tour");
		const tourData = JSON.parse(localStorage.getItem(tour));
		const featuresRaw = tourData ? tourData.features : null;
		let features = [];
		let feature_assets = []

		if(featuresRaw){
			featuresRaw.forEach((featureID, index) => {
				let feature = localStorage.getItem(featureID);
				if(feature){
					feature = JSON.parse(feature);
					features.push(feature);
					if(feature.Feature_Asset_Text) {
						/*todo:  UUID for asset if does not exist already*/
						const f_asset = {
							Asset_Text : feature.Feature_Asset_Text,
							Feature_UUID: feature.Feature_UUID,
							Series_UUID: feature.Series_UUID
						};
						feature_assets.push(f_asset);
						feature.Asset = f_asset;
						delete feature.Feature_Asset_Text;
					}
				}
			});
		}

		this.setState({
			tourToReview: tourData ? tourData : [],
			features: features
		});
	}


	render() {
		return(
			<>
				<div className="row no-gutters animated fadeInLeft min-vh-100 align-items-center justify-content-center">
					<div className="col-12 col-xl-10">
						<div className="dashboard-cards">
							<div className="dashboard-card text-left">
								<div className="dashboard-card-inner">
								</div>
								<h3>Series</h3>
								<JsonView obj={this.state.tourToReview} cssPrefix='external_json_view' highlightedLineNumbers={[2, 4]} />

								<h3>Features</h3>
								<hr/>

								<JsonView obj={this.state.features} cssPrefix='external_json_view' highlightedLineNumbers={[2, 4]} />
							</div>
						</div>
					</div>
				</div>
			</>

		)
	}

}

/*https://hackernoon.com/a-crash-course-on-serverless-with-aws-building-apis-with-lambda-and-aurora-serverless-49885c46e37a*/
