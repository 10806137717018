import {gql} from "@apollo/client";

const userMutation = gql`
mutation userMutation(
    $_id: ID!,
    $User_LanguageID: String,
    $User_Username: String,
    $User_FirstName: String,
    $User_LastName: String,
    $User_Name: String,
    $User_Active: Boolean,
    $User_Pronounce: String,
    $User_Photo: String,
    $User_Level: Int,
    $User_Venmo: String,
    $User_Mobile: String,
    $User_Email: String,
    $User_Bio: String,
    $User_NickName: String,
    $User_Location: String,
    $User_Email_verified: Boolean
) {
	user(
	    _id: $_id,
        User_LanguageID: $User_LanguageID,
        User_Username: $User_Username,
        User_FirstName: $User_FirstName,
        User_LastName: $User_LastName,
        User_Name: $User_Name,
        User_Active: $User_Active,
        User_Pronounce: $User_Pronounce,
        User_Photo: $User_Photo,
        User_Level: $User_Level,
        User_Venmo: $User_Venmo,
        User_Mobile: $User_Mobile,
        User_Email: $User_Email,
        User_Bio: $User_Bio,
        User_NickName: $User_NickName,
        User_Location: $User_Location,
        User_Email_verified: $User_Email_verified
	) {
	    _id
	    User_Username
        User_FirstName
        User_LanguageID
        User_LastName
        User_Name
        User_Active
        User_Pronounce
        User_Photo
        User_Level
        User_Venmo
        User_Mobile
        User_Email
        User_NickName
        User_Bio
        User_Location
        User_Email_verified
	}	
}
`;

export {
    userMutation
}