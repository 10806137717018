const showErrors = (errors) => {
	if(!document.getElementById('general-error')) {
		insertErrorsWrapper();
	}
	errors.forEach(err => {
		appendErrorNode(err);
	});
};

const clearErrors = () => {
	if(document.getElementById('general-error')) {
		document.getElementById('general-error').innerHTML = '';
	}
}

function appendErrorNode(message) {
	console.log(message);
	const wrapper = document.getElementById('general-error');
	const alertNode = document.createElement("div");
	const id = document.getElementById('general-error').childNodes.length + 1;
	alertNode.setAttribute('id', 'g-error-' + id);
	alertNode.setAttribute("class", "alert alert-danger text-center");
	alertNode.innerText = message;
	console.log(alertNode);
	wrapper.appendChild(alertNode);
	console.log(wrapper);
	wrapper.classList.add('d-block');
	// setTimeout(() => {
	// 	document.getElementById('g-error-' + id).remove();
	// },3000);
}

function insertErrorsWrapper() {
	const errDiv = document.createElement("div");
	errDiv.setAttribute('id', 'general-error');
	const appRoot = document.getElementById("app-root");
	appRoot.insertBefore(errDiv ,appRoot.children[0]);
}

export {
	showErrors,
	clearErrors,
	appendErrorNode
}

