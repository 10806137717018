import React from 'react';
import {slideDown} from '../../lib/helpers';
import {validateInput} from '../../lib/validation';

import {seriesMutation} from '../../graphQL/mutation/series.mutatoins';

export default class InitSeries extends React.Component {

	componentDidMount() {
		if(document.querySelector('#Series_Name').value !== '') {
			this.titleDone(true);
		}
		console.log(this.props.viewer)
	}

	titleNext = async (e) => {
		e.preventDefault();
		const titleInput = document.querySelector('#Series_Name');
		const isValid = await validateInput(titleInput);
		if (isValid) {
			titleInput.blur();

			/*todo: fetch all of the tours online*/
			let tours = localStorage.getItem('tours-in-progress') ? JSON.parse(localStorage.getItem('tours-in-progress')) :  [];

			await this.props.apolloClient.mutate({
				mutation: seriesMutation,
				variables: {
					Series_Name: titleInput.value,
					User: this.props.viewer._id,
					Status: 'draft'
				}
			}).then(result => {
				if(!result.loading){
					const seriesId =  result.data.series._id;
					tours.push(seriesId);
					localStorage.setItem('tours-in-progress', JSON.stringify(tours));
					this.props.callback({
						seriesInProgress: seriesId,
						_id: seriesId,
						inProgress: true,
						Series_Name: titleInput.value,
						activeChapter: 0
					});
					this.titleDone();
				}
			}).catch(e => {
				console.log(e);
			});
		}
	}

	titleDone = async (isHidden=false) => {
		const tourTitle = document.querySelector('.tour-title');
		tourTitle.classList.add('done');
		const formComponent = tourTitle.querySelector('.form-group');
		formComponent.classList.remove('floating-label-group');
		formComponent.classList.add('style-secondary');
		await slideDown(document.querySelector('.tour-accordions'), 1000);
		if(isHidden) {
			slideDown(document.querySelector('.tour-title'));
		}
	}

	validateInput = async (e) => {
		await validateInput(e.currentTarget);
	}

	setSeriesTitle = async e => {
		this.props.callback({
			Series_Name: e.target.value
		});
	}

	render() {
		return (
			<>
				<div className="tour-title">
					<h1 className="mt-0 text-900 pt-3">New Tour</h1>
					<div className="form-group floating-label-group mt-0">
						<input type="text"
						       className="form-control color-dark"
						       name="Series_Name"
						       id="Series_Name"
						       placeholder="Name"
						       onBlur={this.validateInput}
						       onChange={this.setSeriesTitle}
						       value={this.props.Series_Name}
						       required/>
						<div className="after-control">
							<label className="floating-label" htmlFor="Series_Name">Tour
								Name</label>
							<span className="focus-effect"/>
						</div>
					</div>
					<div className="text-right">
						<button onClick={this.titleNext} type="submit" className="btn btn-next btn-wide btn-primary">Next</button>
					</div>
				</div>
			</>
		)
	}

}

/*https://codepen.io/nat-davydova/pen/PoYXZxg*/
