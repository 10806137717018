import React from 'react';
import L from 'mapbox.js'
import 'leaflet-draw';
import 'leaflet-geodesy'
import 'mapbox.js/dist/mapbox.css';
import 'leaflet-draw/dist/leaflet.draw.css';

export default class MapBox extends React.Component{


	constructor(props) {
		super(props);
		this.state = {
			map: null,
			editableLayers: null,
			locations: []
		}
	}

	componentDidUpdate = (prevProps, prevState, snapshot) => {
		if(prevProps.lat !== this.props.lat || prevProps.lng !== this.props.lng || prevProps.geo !== this.props.geo){
			this.addGeoJson();
		}
	}

	componentDidMount = async () => {
		if(this.state.map){
			this.state.remove();
		}
		const mapId = this.props['id'] ? this.props['id'] : "geo-map";
		const parent = document.getElementById(mapId + '-wrap');
		let initZoom = this.props.zoom ? this.props.zoom : 6 ,
			initLat = this.props.lat ? this.props.lat : '37.8',
			initLon = this.props.lng ? this.props.lng : '-96';
		L.mapbox.accessToken = 'pk.eyJ1Ijoid3d3bWFzdGVyMSIsImEiOiJjazZmbmxhYngwYjQxM2xtdDdwMjJzYjdnIn0._QtAdUTg9NtC9_R8Caq6Ng';
		const map = L.mapbox.map(mapId).setView([initLat,initLon], initZoom)
			.addLayer(L.mapbox.styleLayer('mapbox://styles/mapbox/streets-v11'))
			.addControl(L.mapbox.geocoderControl('mapbox.places', { keepOpen: false }));

		await this.setStateAsync({
			editableLayers: new L.FeatureGroup(),
			map: map,
			componentWrap: mapId + '-wrap'
		});
		await this.addGeoJson();

		this.state.map.addLayer(this.state.editableLayers);

		let defaultDrawOptions = {
			polyline: true,
			polygon: {
				allowIntersection: false, // Restricts shapes to simple polygons
				drawError: {
					color: '#e1e100', // Color the shape will turn when intersects
					message: '<strong>Oh snap!<strong> you can\'t draw that!' // Message that will show when intersect
				}
			},
			circlemarker: false,
			circle: false, // Turns off this drawing tool
			rectangle: false ,
			marker: false
		};

		const options = {
			position: 'topleft',
			minZoom: 0,
			maxZoom: 25,
			draw: this.props.draw ? this.props.draw : defaultDrawOptions,
			edit: {
				featureGroup: this.state.editableLayers, //REQUIRED!!
				remove: true,
				edit: true
			}
		};
		const drawControl = new L.Control.Draw(options);

		this.state.map.addControl(drawControl);
		this.state.map.on('draw:created', this.showPolygonArea);
		this.state.map.on('draw:edited', this.showPolygonAreaEdited);
		this.state.map.on('draw:updated', this.showPolygonArea);
		this.state.map.on('draw:deleted', this.showPolygonAreaRemoved);

		const defaultView = L.tileLayer('mapbox://styles/mapbox/streets-v11', {
			maxNativeZoom: 19,
			maxZoom: 25
		}).addTo(this.state.map);

		const mapboxStreets = L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
			maxNativeZoom: 19,
			maxZoom: 25
		}).addTo(this.state.map);

		const mapboxsat = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
			maxNativeZoom: 19,
			maxZoom: 25
		}).addTo(this.state.map);



		const basemap = { 'Basic': defaultView, 'Streets': mapboxStreets, 'Aerials': mapboxsat};
		L.control.layers(basemap).addTo(this.state.map); // Layers Control just to switch between the 2 basemaps.

		parent.querySelectorAll('input[name="leaflet-base-layers"]')[0].click();

		/*hide guides on click of any draw tool */

		const leafletControls =  parent.querySelectorAll('.leaflet-control-container .leaflet-top.leaflet-left a');
		leafletControls.forEach(ele => {
			ele.addEventListener('click', (e) => {
				parent.classList.remove('label-overlay-active');
			});
		});

	}


	setStateAsync(state) {
		return new Promise((resolve) => {
			this.setState(state, resolve)
		});
	}

	addGeoJson = async () => {
		if(this.props.geo && this.props.geo.length > 10) {
			const addToMap = L.geoJson(JSON.parse(this.props.geo)).addTo(this.state.map);
			const newLayer = addToMap.getLayers()[0];
			this.state.editableLayers.addLayer(newLayer);
			let mapZoom = this.props.zoom ? this.props.zoom : 6 ;
			this.state.map.setView([this.props.lat,this.props.lng], mapZoom);
			this.state.map.invalidateSize();
		}
	}


	showPolygonAreaRemoved = async (e) => {
		if(this.props.callback){
			this.props.callback({
				zoom: this.state.map.getZoom(),
				lat: this.state.map.getCenter()["lat"],
				lng: this.state.map.getCenter()["lng"],
				geo: JSON.stringify(e.layer ? e.layer.toGeoJSON() : '')
			});
		}
	}

	showPolygonAreaEdited = (e) => {
		e.layers.eachLayer((layer) => { this.showPolygonArea({ layer: layer }); });
	}

	showPolygonArea = async (e) => {
		this.state.editableLayers.clearLayers();
		this.state.editableLayers.addLayer(e.layer);
		if(this.props.callback){
			this.props.callback({
				zoom: this.state.map.getZoom(),
				lat: this.state.map.getCenter()["lat"],
				lng: this.state.map.getCenter()["lng"],
				geo: JSON.stringify(e.layer.toGeoJSON())
			});
		}
	}


	render() {
		return(
			<div id={this.props['id'] ? this.props['id'] + "-wrap" : "geo-map-wrap"} className={this.props.geo && this.props.geo.length > 10 ? 'no-labels' : 'label-overlay-active'}>
				{this.props.description ? <p>{this.props.description}</p> : ''}
				<div id={this.props['id'] ? this.props['id'] : "geo-map"} className="map-canvas" />
				<input required className="form-control" readOnly type="hidden" name="lat" value={this.props.lat ? this.props.lat : ''} />
				<input required className="form-control" readOnly type="hidden" name="lng" value={this.props.lng ? this.props.lng : ''}/>
				<input required className="form-control" readOnly type="hidden" name="geojson" value={this.props.geo ? this.props.geo : ''}/>
			</div>

		)
	}

}
