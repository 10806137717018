import React from 'react';
import parse from 'html-react-parser';
import {validateInput} from '../../lib/validation';
import {slideUp, triggerWinResize} from '../../lib/helpers';

export default class MultiStep extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			startAt : 0
		}
	}

	componentDidMount = async () => {
		this.multiSteps();
		setInterval(()=>{
			triggerWinResize();
		}, 800);
		if(this.props.startAt > 0) {
			this.jumpToStep();
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.startAt !== this.props.startAt) {
			this.jumpToStep();
		}
	}

	jumpToStep () {
		const parent = document.getElementById(this.props.id ? this.props.id : 'multi-steps-canvas-wrap');
		const ele = parent.querySelectorAll('.multisteps-component__progress-btn')[this.props.startAt]; 
		if(ele) {
			ele.click();
		}
	}

	multiSteps = async () => {
		//DOM elements
		const parent = document.getElementById(this.props.id ? this.props.id : 'multi-steps-canvas-wrap');

		const DOMstrings = {
			stepsBtnClass: 'multisteps-component__progress-btn',
			stepsBtns: parent.querySelectorAll('.multisteps-component__progress-btn'),
			stepsBar: parent.querySelector('.multisteps-component__progress'),
			stepsWrap: parent.querySelector('.multisteps-component__wrap'),
			stepsWrapTextareas: parent.querySelectorAll('.multisteps-component__textarea'),
			stepFormPanelClass: 'multisteps-component__panel',
			stepFormPanels: parent.querySelectorAll('.multisteps-component__panel'),
			stepPrevBtnClass: 'js-btn-prev',
			stepNextBtnClass: 'js-btn-next',
			doneBtnClass: 'js-btn-done'
		};
		//remove class from a set of items
		const removeClasses = (elemSet, className) => {
			elemSet.forEach(elem => {
				elem.classList.remove(className);
			});
		};
		//return exect parent node of the element
		const findParent = (elem, parentClass) => {
			let currentNode = elem;
			while (!(currentNode.classList.contains(parentClass))) {
				currentNode = currentNode.parentNode;
			}
			return currentNode;
		};
		//get active button step number
		const getActiveStep = elem => {
			return Array.from(DOMstrings.stepsBtns).indexOf(elem);
		};
		//set all steps before clicked (and clicked too) to active
		const setActiveStep = (activeStepNum) => {
			//remove active state from all the state
			removeClasses(DOMstrings.stepsBtns, 'js-active');
			//set picked items to active
			DOMstrings.stepsBtns.forEach((elem, index) => {
				if (index <= (activeStepNum)) {
					elem.classList.add('js-active');
				}
			});
		};
		const getActivePanel = () => {
			let activePanel;
			DOMstrings.stepFormPanels.forEach(elem => {
				if (elem.classList.contains('js-active')) {
					activePanel = elem;
				}
			});
			if(typeof activePanel === 'undefined') {
				activePanel = Array.from(DOMstrings.stepFormPanels)[0];
			}
			return activePanel;
		};
		//open active panel (and close unactive panels)
		const setActivePanel = activePanelNum => {
			removeClasses(DOMstrings.stepFormPanels, 'js-active');
			DOMstrings.stepFormPanels.forEach((elem, index) => {
				if (index === (activePanelNum)) {
					elem.classList.add('js-active');
					setFormHeight(elem);
				}
			})
		};
		const formHeight = (activePanel) => {
			const activePanelHeight = activePanel.offsetHeight;
			DOMstrings.stepsWrap.style.height = `${activePanelHeight}px`;
		};
		const setFormHeight = () => {
			const activePanel = getActivePanel();
			formHeight(activePanel);
		}
		DOMstrings.stepsBar.addEventListener('click', async e => {
			const eventTarget = e.target;
			/*const prevSib = eventTarget.previousSibling;
			if(prevSib && !prevSib.classList.contains('js-active')){
				prevSib.classList.add('blinking-error');
				setTimeout(() => {
					prevSib.classList.remove('blinking-error');
				}, 3000);
				return
			}*/

			if (!eventTarget.classList.contains(`${DOMstrings.stepsBtnClass}`)) {
				return;
			}
			const stepToActivate = getActiveStep(eventTarget);
			const currentActivePanel = DOMstrings.stepsWrap.querySelector('.js-active.multisteps-component__panel');
			const validate = this.props.validate === "true";
			const currentlyActive = Array.from(DOMstrings.stepFormPanels).indexOf(currentActivePanel);
			await this.validateStep(currentActivePanel);
			if(validate){
				if(stepToActivate > currentlyActive && !currentActivePanel.classList.contains('has-errors')) {
					setActiveStep(stepToActivate);
					setActivePanel(stepToActivate);
				} else if(stepToActivate < currentlyActive){
					setActiveStep(stepToActivate);
					setActivePanel(stepToActivate);
				}
			} else{
				setActiveStep(stepToActivate);
				setActivePanel(stepToActivate);
			}
		});
		
		//PREV/NEXT/DONE BTNS CLICK
		DOMstrings.stepsWrap.addEventListener('click', async e => {
			const eventTarget = e.target;
			//check if we clicked on `PREV` or NEXT` or 'DONE' buttons 
			const validate = this.props.validate === "true";
			if (!((eventTarget.classList.contains(`${DOMstrings.stepPrevBtnClass}`)) || (eventTarget.classList.contains(`${DOMstrings.stepNextBtnClass}`)) || (eventTarget.classList.contains(`${DOMstrings.doneBtnClass}`)))) {
				return;
			}
			if ( (eventTarget.classList.contains(`${DOMstrings.stepPrevBtnClass}`)) || (eventTarget.classList.contains(`${DOMstrings.stepNextBtnClass}`)) ){
				const activePanel = findParent(eventTarget, `${DOMstrings.stepFormPanelClass}`);
				let activePanelNum = Array.from(DOMstrings.stepFormPanels).indexOf(activePanel);
				if (eventTarget.classList.contains(`${DOMstrings.stepPrevBtnClass}`)) {
					activePanelNum--;
				} else {
					if(validate) {
						await this.validateStep(activePanel);
						if(!activePanel.classList.contains('has-errors')){
							activePanelNum++;
						}
					} else {
						activePanelNum++;
					}
				}
				if(validate && !activePanel.classList.contains('has-errors')) {
					setActiveStep(activePanelNum);
					setActivePanel(activePanelNum);
				} else if(!validate){
					setActiveStep(activePanelNum);
					setActivePanel(activePanelNum);
				}
			}else{
				const activeStep = findParent(eventTarget, `${DOMstrings.stepFormPanelClass}`);
				let hasErrors = false;
				for (const formControl of activeStep.querySelectorAll('.form-control')) {
					const isValid = await validateInput(formControl);
					if(!isValid) {
						activeStep.classList.add('has-errors');
						hasErrors = true
						break;
					}
				}
				if(!hasErrors) {
					activeStep.classList.remove('has-errors');
					this.props.onComplete.callback(e);
				} else{
					setTimeout(() => {
						const errorElement = activeStep.querySelector('.multistep-general-error');
						slideUp(errorElement);
						setTimeout(() => {
							activeStep.classList.remove('has-errors');
							errorElement.removeAttribute('style');
						}, 500);
					}, 2000);
				}
			}			
		});
		window.addEventListener('load', setFormHeight, false);
		window.addEventListener('resize', setFormHeight, false);
	}


	validateStep = async (activeStep) => {
		let hasErrors = false;
		for (const formControl of activeStep.querySelectorAll('.form-control')) {
			const isValid = await validateInput(formControl);
			if(!isValid) {
				activeStep.classList.add('has-errors');
				hasErrors = true
				break;
			}
		}
		if(!hasErrors) {
			activeStep.classList.remove('has-errors');
		} else{
			setTimeout(() => {
				const errorElement = activeStep.querySelector('.multistep-general-error');
				slideUp(errorElement);
				setTimeout(() => {
					activeStep.classList.remove('has-errors');
					errorElement.removeAttribute('style');
				}, 500);
			}, 2000);
		}
	}

	hideMultiStepError = async (e) => {
		e.currentTarget.closest('.multisteps-component__panel').classList.remove('has-errors');
	}

	render() {
		return (
			<div id={this.props.id ? this.props.id : 'multi-steps-canvas-wrap' }>
				<div className="multi-steps-canvas">
					<div className="content">
						<div className="content__inner">
							<div>
								<div className="multisteps-component">
									<div className="row">
										<div className="col-12 col-lg-10 ml-auto mr-auto mb-4">
											<div className="multisteps-component__progress">
												{
													this.props.steps.map((step, i) => {
														return (
															<button key={'step-btn-' + i} className={i <= 0 ? 'multisteps-component__progress-btn js-active' : 'multisteps-component__progress-btn'} type="button" title={step.name}>{step.name} {step.note && parse(step.note)}</button>
														);
													})
												}
											</div>
										</div>
									</div>

									<div className="row no-gutters">
										<div className="col-12 m-auto">
											<div className="multisteps-component__wrap">
												{
													this.props.steps.map((step, i) => {
														return (
															<div key={'multistep-' + i}>
																<div data-validate={step.validate ? step.validate : "false" } className={i === 0 ? 'multisteps-component__panel shadow p-4 rounded bg-white js-active' : 'multisteps-component__panel shadow p-4 rounded bg-white'} data-animation="scaleOut">
																	<div className="row align-items-center mb-4">
																		<div className="col-12 col-sm-8">
																			<h3 className="h4 m-0 multisteps-component__title">{step.name}</h3>
																		</div>
																		<div className="col-12 col-sm-4">
																			{i < this.props.steps.length -1 ? (
																				<div className="button-row d-flex justify-content-end">
																					{!!step.allowSkip ? <button onClick={e => {
																						window.dispatchEvent(new CustomEvent('stepSkipped'));
																					}} className="btn btn-secondary">Skip This Step <i className="fa fa-angle-double-right" /></button> : ''}
																					<button className="btn btn-wide btn-secondary js-btn-next ml-2" type="button" title="Next">Next</button>
																				</div>
																			) : '' }

																			{
																				i === this.props.steps.length -1 && this.props.onComplete ? (
																					<div className="button-row d-flex">
																						<button className="btn btn-wide btn-secondary ml-auto js-btn-done" type="button">
																							{this.props.onComplete.button}
																						</button>
																					</div>
																				) : ''
																			}

																		</div>
																	</div>
																	<div className="multisteps-component__content">
																		{this.props.validate && step.defaultError ? (
																			<div className="multistep-general-error with-close-btn invalid-feedback alert alert-danger small">
																				{step.defaultError}
																				<button onClick={this.hideMultiStepError} type="button" className="close" aria-label="Close">
																					<span className="fal fa-times" aria-hidden="true"/>
																				</button>
																			</div>
																		) : ''}
																		{step.content ? step.content : ''}
																	</div>
																</div>
															</div>
														);
													})
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
