import React from "react";
import { Link } from "react-router-dom";
import InitSeries from "../partials/NewSeries";
import Series from "../partials/Series";
import { slideDown, slideUp, syncBtnStatus, syncSeriesToDb } from "../../lib/helpers";
import SeriesFeatures from "../partials/Feature";
import FinalizeSeries from "../partials/FinalizeSeries";
import {oneSeriesQuery} from "../../graphQL/query/series.query";
import Loading from "../partials/Loading";
import {featureMutation} from "../../graphQL/mutation/feature.mutation";
import {seriesMutation} from "../../graphQL/mutation/series.mutatoins";
import {config} from "../../config";


export default class SeriesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      checkpoint: 0,
      activeChapter: -1,
      seriesInProgress: undefined,
      /*series*/
      _id: undefined,
      Status: 'draft',
      Series_UUID: undefined,
      Series_Name: "",
      Series_wikiID: undefined,
      Series_lat: 37.8,
      Series_lng: -96,
      Series_geo: undefined,
      Series_Era: [],
      Series_Tag: [],
      Series_MapZoom: 6,
      Series_BoxImgCap: "",
      /* finalize */
      Series_Teaser: "",
      Series_Summary: "",
      finalCheckpoint: 0,
      finalizationStarted: false,
      /*summary*/
      summaryCheckpoint: 0,
      showSummary: false,
      /*Features related*/
      FeaturesIdList: [],
      User: this.props.viewer._id,
      syncTimer: undefined,
      canAddFeature: false
    };
  }

  componentDidMount = async () => {
    let params = new URLSearchParams(window.location.search);
    let tourToEdit = undefined;
    if(params.get('edit')){
      tourToEdit = params.get('edit');
    }
    if(tourToEdit){
      localStorage.removeItem(tourToEdit);
      await this.props.apolloClient.query({
        query: oneSeriesQuery,
        variables: {
          _id: tourToEdit
        }
      }).then(result => {
        if(!result.loading){
          if(result.data && result.data.seriesOne) {
            let series = {...result.data.seriesOne};
            this.state.seriesInProgress = series._id;
            if(series.Series_Era){
              series.Series_Era = series.Series_Era.split(',');
            } else {
              series.Series_Era = []
            }
            if(series.Series_Tag){
              series.Series_Tag =  series.Series_Tag.split(',');
            } else {
              series.Series_Tag = []
            }
            if(series.User && series.User._id){
              series.User = series.User._id
            }
            if(series.Series_Teaser || series.Series_Summary) {
              series.finalizationStarted = true
            }

            console.log(series);

            this.setStateAsync({
              ...series,
            }).then( async () => {
              for(let f of series.Features){
                localStorage.setItem(f._id, JSON.stringify(f));
                for(let a of f.Assets){
                  localStorage.setItem(a._id, JSON.stringify(a));
                }
              }
              await this.setStateAsync({
                loading: false
              });
              this.canAddFeatures();
              if (series.activeChapter < 0) {
                setTimeout(() => {
                  this.showAccordion(0);
                }, 500);
              }
            })

          } else {
            this.setState({
              loading: false
            });
          }
        }
      }).catch(e => {
        console.log(e);
        window.location = '/';
      });
    } else {
      this.setState({
        loading: false
      })
    }
  };

  canAddFeatures = () => {
    this.setStateAsync({canAddFeature: !!(this.state.Series_Era && this.state.Series_Tag && this.state.Series_Name)});
  }


  setStateAsync = (state) => {
    if(state){
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };


  dataCallback = async (data) => {
    if (typeof data === "object") {
      await this.setStateAsync({
        ...data,
      });
    }
    localStorage.setItem(this.state._id, JSON.stringify(this.state));
    this.canAddFeatures();
    setTimeout(() => {
      clearTimeout(this.state.syncTimer);
      syncBtnStatus('reset');
      this.prepSeriesDbSync();
    }, 100);
  };

  prepSeriesDbSync = () => {
    this.state.syncTimer = setTimeout( () => {
      syncSeriesToDb(this.props.apolloClient,seriesMutation, this.state.seriesInProgress);
    }, config.dbSyncDelay);
  }

  initFeature = async (formElement) => {
    const nameField = document.getElementById("NewFeatureName"),
      Feature_Name = nameField.value;
    if (Feature_Name.length > 2) {
      this.props.apolloClient.mutate({
        mutation: featureMutation,
        variables: {
          Series: this.state._id,
          User: this.props.viewer._id,
          Feature_Name: Feature_Name,
          Feature_Sequence: this.state.FeaturesIdList.length
        }
      }).then(async result => {
        if(!result.loading) {
          if(result.data.feature){
            localStorage.setItem(result.data.feature._id, JSON.stringify(result.data.feature));
            setTimeout(async () => {
              await this.setStateAsync({
                FeaturesIdList: [...this.state.FeaturesIdList, result.data.feature._id]
              });
              localStorage.setItem(result.data.feature._id, JSON.stringify({...result.data.feature}));
              /*feature issue*/
              localStorage.setItem(this.state._id, JSON.stringify(this.state));
              const accordionTabs = document.querySelectorAll(".accordion-tab"),
                  totalTabs = document.querySelectorAll(".feature-tab").length,
                  targetTab = accordionTabs[totalTabs];
              targetTab.querySelector(".accordion-tab-trigger").click();
            }, 500);
          }
        }
      }).catch(e => {
        syncBtnStatus('loaded');
        console.log('Failed to sync series: ', e);
      });

    } else {
      nameField.classList.add("error");
    }
  };

  showAccordion(accordionIndex = 0) {
    if (this.state.activeChapter !== accordionIndex) {
      this.setState({
        activeChapter: accordionIndex,
      });
    } else {
      this.setState({
        activeChapter: -1,
      });
    }
  }

  initFinalization() {
    // if(this.validateFeatures()) {
    //
    // }
    this.setState({
      activeChapter: 1000,
      finalizationStarted: true,
    });
  }

  validateFeatures = () => {
    let validationError = 0;
    for(let fid of this.state.FeaturesIdList) {
      const f = localStorage.getItem(fid) ? JSON.parse(localStorage.getItem(fid)) : localStorage.getItem(fid);
      if(!(f.Feature_BoxImg && f.Feature_BoxImgCap && f.Feature_BoxImg.length && f.Feature_BoxImgCap.length && f.Feature_Tag && f.Feature_Tag.length && f.Feature_Era && f.Feature_Era.length)) {
        validationError++;
        const invalidFeature = document.getElementById('feature-' + f._id);
        if(!invalidFeature.classList.contains('active')){
          invalidFeature.querySelector(".accordion-tab-trigger").click();
        }
        const err = document.createElement("div");
        err.classList.add('alert');
        err.classList.add('bg-danger');
        err.setAttribute('id', 'error-' + f._id)
        var errText = document.createTextNode("Please complete following feature(s) before adding new one.");
        err.appendChild(errText);
        invalidFeature.prepend(err);
        console.log(err.getBoundingClientRect().top);
        window.scroll({
          top: err.getBoundingClientRect().top,
          behavior: 'smooth'
        });
        setTimeout(() => {
          err.remove();
        }, 10000);
        break;
      }
    }
    if(validationError !== 0) {
      /*todo: */
      // alert('Please complete the current features before adding new one.');
    }
    return (validationError === 0)
  }

  showFeatureNameForm = async (e) => {

    if(this.validateFeatures()){
      if (this.state.activeChapter !== 1000) {
        await this.setStateAsync({
          activeChapter: 999,
        });
        slideDown(document.getElementById("featureNameForm"));
      }
    }
  }

  hideFeatureNameForm(e) {
    slideUp(document.getElementById("featureNameForm"));
    this.setState({
      activeChapter: -1,
    });
  }

  onChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {


    const finalizeTourUI = this.state.FeaturesIdList && this.state.FeaturesIdList.length >= 1 && !this.state.finalizationStarted ? (
        <button
            onClick={(e) => {
              this.initFinalization();
            }}
            className="btn btn-success btn-min-width"
        >
          <i className="far fa-plus" /> Finalize Tour
        </button>
    ) : <></>;

    const addFeatureUI =
      this.state.canAddFeature && this.state.activeChapter === 999 ? (
        <div className="border-top pt-4 pb-4 add-newFeature text-right">
          <form
            id="featureNameForm"
            onSubmit={(e) => {
              e.preventDefault();
              this.initFeature(e.currentTarget);
            }}
            style={{ display: "none" }}
          >
            <div className="row">
              <div className="col">
              </div>
            </div>
            <div className="form-group floating-label-group mt-0">
              <input
                  type="text"
                  className="form-control color-dark"
                  id="NewFeatureName"
                  name="name"
                  placeholder="Enter Feature Name"
                  onChange={(e) => {
                    e.currentTarget.classList.remove("error");
                  }}
                  required
              />
              <div className="after-control">
                <label className="floating-label" htmlFor="NewFeatureName">
                  Enter Feature Name
                </label>
                <span className="focus-effect" />
              </div>
            </div>

            <button
                className="btn btn-outline-primary mb-2 mr-2"
                type="submit"> Add Feature
            </button>
            <button
                className="btn btn-outline-danger mb-2"
                type="button"
                onClick={(e) => {
                  this.hideFeatureNameForm(e);
                }}
            >
              Cancel New Feature
            </button>
          </form>

          {/*todo: check if the all of the added features are valid and only then allow to finalize*/}

        </div>
      ) : this.state.canAddFeature && this.state.activeChapter !== 999 ? (
          <div className="text-right">
            <button
                onClick={(e) => {
                  this.showFeatureNameForm(e);
                }}
                className="btn btn-outline-primary mr-1"
            >
              <i className="far fa-plus" /> Add Feature
            </button>
            {finalizeTourUI}
          </div>
      ) : <></>;




    return (
        <>
          {
            this.state.loading ? <Loading /> :
                <>
                  <button id="btn-sync" className="sync-data btn btn-sync">
                    <i className="far fa-save normal-state" />
                    <i className="far fa-hourglass loading-state" />
                    <i className="far fa-check loaded-state" />
                  </button>
                  <div className="row no-gutters animated fadeInLeft min-vh-100 align-items-center justify-content-center">
                    <div className="col-12 col-lg-10 col-xl-9">
                      <div className="page-content position-relative">
                        <Link
                            to="/tours"
                            title="Close Tour"
                            className="closeAndGoBack"
                        >
                          <i className="fal fa-times" />
                        </Link>
                        {this.props.viewer && <InitSeries viewer={this.props.viewer} apolloClient={this.props.apolloClient} callback={this.dataCallback} {...this.state} />}
                        <div
                            id="new-tour-accordions"
                            className="accordion-tabs tour-accordions"
                            style={{ display: "none" }}
                        >
                          <div
                              className={
                                this.state.activeChapter === 0
                                    ? "accordion-tab active"
                                    : "accordion-tab"
                              }
                          >
                            <button
                                className="accordion-tab-trigger"
                                onClick={(e) => {
                                  this.showAccordion(0);
                                }}
                                data-collapse={".accordion-tab-content"}
                            >
                              Tour Settings
                            </button>
                            {this.state.activeChapter === 0 ? (
                                <div className="accordion-tab-content p-0">
                                  <Series
                                      {...this.state}
                                      callback={this.dataCallback}
                                  />
                                </div>
                            ) : (
                                ""
                            )}
                          </div>

                          {this.state.FeaturesIdList.map((feature, index) => {
                            return (
                                <div
                                    key={"feature-" + index}
                                    id={"feature-" + feature}
                                    className={
                                      this.state.activeChapter === index + 1
                                          ? "accordion-tab active feature-tab"
                                          : "accordion-tab feature-tab"
                                    }
                                >
                                  <button
                                      className="accordion-tab-trigger"
                                      onClick={(e) => {
                                        this.showAccordion(index + 1);
                                      }}
                                      data-collapse={".accordion-tab-content"}
                                  >
                                    {
                                      JSON.parse(localStorage.getItem(feature)).Feature_Name
                                    }
                                  </button>
                                  {this.state.activeChapter === index + 1 ? (
                                      <div className="accordion-tab-content p-0">
                                        <SeriesFeatures
                                            {...this.state}
                                            featureId={feature}
                                            apolloClient={this.props.apolloClient}
                                            viewer={this.props.viewer}
                                            seriesId={this.state._id}
                                            index={index}
                                            onComplete={this.dataCallback}
                                        />
                                      </div>
                                  ) : <></>}
                                </div>
                            );
                          })}

                          {this.state.FeaturesIdList &&
                          this.state.FeaturesIdList.length >= 1 &&
                          this.state.finalizationStarted ? (
                              <div
                                  className={
                                    this.state.activeChapter === 1000 &&
                                    this.state.finalizationStarted
                                        ? "accordion-tab active"
                                        : "accordion-tab"
                                  }
                              >
                                <div>
                                  <button
                                      className="accordion-tab-trigger"
                                      onClick={(e) => {
                                        this.showAccordion(1000);
                                      }}
                                      data-collapse={".accordion-tab-content"}
                                  >
                                    Finalize Tour
                                  </button>
                                </div>
                                {this.state.activeChapter === 1000 ? (
                                    <div className="accordion-tab-content p-0">
                                      <FinalizeSeries
                                          {...this.state}
                                          callback={this.dataCallback}
                                      />
                                    </div>
                                ) : <></>}
                              </div>
                          ) : <></>}

                          {addFeatureUI}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
          }
        </>

    );
  }
}

/*https://codepen.io/nat-davydova/pen/PoYXZxg*/
