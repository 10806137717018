import React from "react";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";

export default class Dashboard extends React.Component {
  componentDidMount = async () => {

  };

  render() {
    return (
      <>
        <div className="row no-gutters animated fadeInLeft min-vh-100 align-items-center justify-content-center">
          <div className="col-12 col-xl-10">
            <div className="dashboard-cards">
              <h1>Welcome!</h1>
              <div className="row justify-content-center">
                <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                  <div className="dashboard-card">
                    <div className="dashboard-card-inner">
                      <ReactSVG src="./svg-icons/profile.svg" />
                      <strong>Your Profile</strong>
                      {
                       this.props && this.props.viewer && this.props.viewer.User_Username && this.props.viewer.User_Location && this.props.viewer.User_Mobile  && this.props.viewer.User_Bio  && this.props.viewer.User_LanguageID && this.props.viewer.User_Photo ? <>
                         <span className="dashboard-card-badge success"><i className="far fa-check-circle" /> Complete</span>
                       </> : <>
                         <span className="dashboard-card-badge danger"><i className="far fa-exclamation-circle" /> Please complete your profile.</span>
                       </>
                      }
                    </div>
                    <Link to="/profile">Profile</Link>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                  <div className="dashboard-card">
                    <div className="dashboard-card-inner">
                      <ReactSVG src="./svg-icons/new-tour.svg" />
                      <strong>Add A Tour</strong>
                    </div>
                    <Link to="/tour">Add A Tour</Link>
                  </div>
                </div>
                {
                  this.props && this.props.viewer && this.props.viewer.SeriesCount > 0 ? <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                    <div className="dashboard-card">
                      <div className="dashboard-card-inner">
                        <ReactSVG src="./svg-icons/review.svg" />
                        <strong>Edit Tours</strong>
                        <span className="dashboard-card-badge">You have {this.props.viewer.SeriesCount} Tour{this.props.viewer.SeriesCount > 1 && "s"}</span>
                      </div>
                      <Link to="/tours">Edit Tours</Link>
                    </div>
                  </div> : ''
                }
              </div>
              <div className="row justify-content-center mt-5">
                <Link to="/logout" className="btn btn-text text-white"><i className="fa fa-power-off mr-2" />Logout</Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

/*https://hackernoon.com/a-crash-course-on-serverless-with-aws-building-apis-with-lambda-and-aurora-serverless-49885c46e37a*/
