import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import { ApolloClient, InMemoryCache,createHttpLink, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

let authApi = 'https://auth.maverickmaven.com';
console.log(window.location.host);
if(window.location.host === 'localhost:3000'){
    authApi = 'http://localhost:4000'
}
const httpLink = createHttpLink({
    uri: authApi + '/graphql',
});

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : null;
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    credentials: "include",
    cache: new InMemoryCache()
});

ReactDOM.render(
    <ApolloProvider client={client}>
        <App apolloClient={client} />
    </ApolloProvider>,
  document.getElementById('root')
);


