import React from "react";
import { getS3Url, triggerWinResize, uploadToS3 } from "../../lib/helpers";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import { getViewer } from "../../lib/getViewer";

export default class ProfileUploadImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageSelection: 0,
      hasCropped: false,
      selectedImageDataUrl: undefined,
      selectedImageType: undefined,
      cropper: undefined,
      imageUrl: undefined,
      viewer: undefined,
    };
  }

  componentDidMount = async (e) => {
    const uploadedImageFile = this.props.imageFile;
    await this.setState({
      imageSelection: 1,
      selectedImageType: uploadedImageFile.type,
    });
    this.displayImage(
      uploadedImageFile,
      document
        .getElementById(this.props["id"] ? this.props["id"] : "images-upload")
        .querySelector("#selectedImgWrap")
    );
  };

  displayImage = async (image, ele) => {
    let objectURL = URL.createObjectURL(image);
    let img = new Image();
    img.src = objectURL;
    img.onload = this.onSelectedImageLoad;
    img.id = "selectedImg";
    ele.appendChild(img);
  };

  onSelectedImageLoad = (e) => {
    e.currentTarget.parentElement.classList.remove("loading");
    triggerWinResize();
    this.initCropper();
  };

  initCropper = () => {
    const el = document
      .getElementById(this.props["id"] ? this.props["id"] : "images-upload")
      .querySelector("#selectedImg");
    const cropper = new Cropper(el, { aspectRatio: 1 });
    this.setState({
      cropper: cropper,
    });
  };

  cropAndUpload = async (e) => {
    const btn = e.currentTarget;
    btn.classList.add("loading");
    const croppedBase64Data = this.state.cropper
      .getCroppedCanvas()
      .toDataURL(this.state.selectedImageType);
    await this.setState({
      hasCropped: true,
      selectedImageDataUrl: croppedBase64Data,
    });
    await this.displayCroppedImage();
    // btn.classList.remove('loading');
    this.state.viewer = await getViewer(this.props.apolloClient);
    setTimeout(async () => {
      await this.uploadSelectedImage(btn);
    }, 500);
  };

  displayCroppedImage = async () => {
    this.state.cropper.destroy();
    document.getElementById("selectedImg").remove();
    const appendTo = document
      .getElementById(this.props["id"] ? this.props["id"] : "images-upload")
      .querySelector("#selectedImgWrap");
    let img = new Image();
    img.src = this.state.selectedImageDataUrl;
    img.id = "croppedImg";
    appendTo.appendChild(img);
    setTimeout(() => {
      triggerWinResize();
    }, 250);
  };

  uploadSelectedImage = async (btn) => {
    //const fileName = uuidv1();
    setTimeout(() => {}, 1000);
    try {
      const s3SignedData = await getS3Url({
        file_name: this.state.viewer["_id"],
        file_type: this.state.selectedImageType,
      });
      const s3Response = await uploadToS3({
        ...s3SignedData,
        file: this.state.selectedImageDataUrl,
        fileType: this.state.selectedImageType,
      });
      if (s3Response.status === 200) {
        document
          .getElementById(this.props["id"] ? this.props["id"] : "images-upload")
          .querySelector(".s3Image").value = s3SignedData.url;
        await this.setState({
          imageUrl: s3SignedData.url,
        });
        if(this.props.handler) {
          this.props.handler({
            success: true,
            imageUrl: s3SignedData.url
          });
        }
      }
      btn.classList.remove("loading");
    } catch (e) {
      console.warn(e);
      btn.classList.remove("loading");
      this.props.handler({
        success: false
      });
    }
  };

  render() {
    let imageSelectionUi = "";
    imageSelectionUi = (
      <div className="animated fadeInUp position-relative">
        <button
          className="btn btn-wide btn-secondary ml-auto "
          onClick={this.cropAndUpload}
          style={{ marginBottom: "5px" }}
        >
          <span className="btn-text">Crop and Upload</span>
          <span className="spinner">
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
          </span>
        </button>
        <div className="row">
          <div className="col-12 col-md-4" />
        </div>
        <div className="image-viewport" id="selectedImgWrap" />
      </div>
    );
    return (
      <div
        id={
          this.props["id"] ? this.props["id"] + "-wrap" : "images-upload-wrap"
        }
      >
        <div
          id={this.props["id"] ? this.props["id"] : "images-upload"}
          className="images-upload-lists"
        >
          <input
            type="hidden"
            value={this.props.defaultImage ? this.props.defaultImage : ""}
            className="s3Image form-control"
            name="s3Image"
            required
          />
          {imageSelectionUi}
        </div>
      </div>
    );
  }
}

/*
Resource: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
* */
