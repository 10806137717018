const emailFilter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
const inputTypes = ['text', 'url', 'search', 'tel', 'date', 'password'];

const validateForm = async (form) => {
	let errors = false;
	if (document.getElementById('general-error')) {
		document.getElementById("general-error").innerHTML = " ";
	}
	for (let element of form.elements) {
		if (element.hasAttribute('required')) {
			const elementType = element.getAttribute('type');
			if (inputTypes.includes(elementType) && !(element.value && (element.value.trim()).length > 0)) {
				console.log(element);
				console.log(element.value);
				console.log('1');
				element.classList.add('error');
				errors = true;
				break;
			} else if (elementType === 'email' && !(emailFilter.test(element.value) && element.value.length >= 4)) {
				console.log('2');
				element.classList.add('error');
				errors = true;
				break;
			} else if(elementType === 'checkbox' && !(element.checked)) {
				console.log('3');
				element.classList.add('error');
				errors = true;
				break;
			} else if (elementType === 'password' && element.getAttribute('data-check-strength') !== null && !await passwordStrengthValidator(element)) {
				console.log('4');
				element.classList.add('error');
				errors = true;
				break;
			} else if (element.getAttribute('data-match') !== null && !await matchValues(element)) {
				console.log('5');
				element.classList.add('error');
				errors = true;
				break;
			} else {
				element.classList.remove('error');
			}
		}
	}
	if (form.querySelectorAll('.form-control.error').length) {
		form.querySelectorAll('.form-control.error')[0].focus();
	}
	return errors;
};

const validateInput = async (element) => {
	const elementType = element.getAttribute('type');
	const elementRequired = element.getAttribute('required') === '';
	if(elementRequired) {
		if (!element.value) {
			addErrorClasses(element);
			return false;
		} else if (elementType && elementType === 'email' && !emailFilter.test(element.value)) {
			addErrorClasses(element);
			return false;
		} else {
			removeErrorClasses(element);
			return true;
		}
	} else{
		return true;
	}
};


const validateImageFile = async (file, {width = 0, height = 0}) => {
	return new Promise(async (resolve, reject) => {
		let img = new Image();
		if (file && file.name) {
			img.src = URL.createObjectURL(file);
			img.onload = () => {
				let isValid = false;
				if (width !== 0 && height !== 0) {
					isValid = img.width === width && img.height === height
				} else if (width !== 0) {
					isValid = img.width === width
				} else if (height !== 0) {
					isValid = img.height === height
				}
				resolve(isValid);
			};
			img.onerror = reject;
		} else {
			resolve(false);
		}
	})
};

function removeErrorClasses(element) {
	element.classList.remove('error');
	element.classList.remove('error-required');
}

function addErrorClasses(element) {
	element.classList.add('error');
	element.classList.add('error-required');
}

const passwordStrengthValidator = async (element) => {
	const value = element.value;
	const specialCharacters = ['_', '#', '%', '*', '@'];
	const prohibitedCharacters = ['$', '&', '=', '!'];
	const minSize = 7;
	const maxSize = 16;

	let progress = 0;
	let hasValidLength = value.length >= minSize && value.length <= maxSize,
		noProhibitedCharacters = !new RegExp("[" + prohibitedCharacters.join('') + "]").test(value),
		hasSpecialCharacters = new RegExp("[" + specialCharacters.join('') + "]").test(value),
		hasUpperCase = value.replace(/[^A-Z]/g, "").length > 0,
		hasNumbers = /\d/.test(value);

	if (hasValidLength) {
		element.classList.remove('error-length');
		progress += 20;
	} else {
		element.classList.add('error-length');
	}
	if (noProhibitedCharacters) {
		element.classList.remove('error-prohibited-characters');
		progress += 20;
	} else {
		element.classList.add('error-prohibited-characters');
	}
	if (hasSpecialCharacters) {
		element.classList.remove('error-special-characters');
		progress += 20;
	} else {
		element.classList.add('error-special-characters');
	}
	if (hasUpperCase) {
		element.classList.remove('error-uppercase');
		progress += 20;
	} else {
		element.classList.add('error-uppercase');
	}
	if (hasNumbers) {
		element.classList.remove('error-number');
		progress += 20;
	} else {
		element.classList.add('error-number');
	}
	const progressbar = element.getAttribute('data-progressbar') !== null ? document.querySelector(element.getAttribute('data-progressbar')) : false;
	if (progressbar) {
		progressbar.style.width = progress + '%';
	}
	const isValid = hasValidLength && noProhibitedCharacters && hasSpecialCharacters && hasUpperCase && hasNumbers;
	if (!isValid) {
		element.classList.add('error');
	} else {
		element.classList.remove('error');
	}
	return isValid;
}

const matchValues = async (element) => {
	const value = element.value;
	const elementToMatch = document.querySelector(element.getAttribute('data-match'));
	const valueToMatch = elementToMatch.value;
	if (valueToMatch !== value) {
		element.classList.add('error');
	} else {
		element.classList.remove('error');
	}
	return (valueToMatch === value);
}

export {
	validateForm,
	validateInput,
	matchValues,
	passwordStrengthValidator,
	validateImageFile
}
