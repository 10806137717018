import React from 'react';

import * as tagsJson from '../../temp-data/tags.json';
import * as eraJson from '../../temp-data/eras.json';

import ReactTags from 'react-tag-autocomplete';

import { Scrollbars } from 'react-custom-scrollbars';

export default class EraAndTags extends React.Component{

	constructor(props) {
		super(props);
		this.state = {
			parentID: undefined,
			parentEle: undefined,
			tags: [],
			tagSuggestions:[],
			eraSuggestions:[],
			eras: [],
			delimiters: []
		}
		this.reactTags = React.createRef()
	}


	componentDidUpdate = async (prevProps, prevState, snapshot) => {
		if(prevProps.eras !== this.props.eras || prevProps.tags !== this.props.tags) {
			this.populateSelectedValues();
		}
	}


	componentDidMount = async () => {
		const parentID = this.props['id'] ? this.props['id'] : "era-tags";
		await this.setState({
			parentID: parentID
		});
		this.populateSelectedValues();
		const tags = tagsJson.tags.map((tag) => {
			return {
				id: tag,
				name: tag
			}
		});
		const eras = eraJson.Eras.map((era) => {
			return {
				id: era,
				name: era
			}
		})
		await this.setState({
			tagSuggestions: tags,
			eraSuggestions: eras
		});
		try {
			/*todo: get data from server, currently CORS not enabled*/
			/*const tagList = await getData(config.serverAPI + 'tags.cfm');
			const eraList = await getData(config.serverAPI + 'eras.cfm');*/
		} catch (e) {
			console.warn(e)
		}
	}

	populateSelectedValues () {
		let tags = [], eras= [];
		if(this.props.tags){
			let tempTags;
			if(typeof this.props.tags === 'string'){
				tempTags = this.props.tags.split(',');
			} else {
				tempTags = this.props.tags;
			}
			tags = tempTags.map((tag) => {
				return {
					id: tag,
					name: tag
				}
			});
		}
		if(this.props.eras){
			let tempEra;
			if(typeof this.props.eras === 'string'){
				tempEra = this.props.eras.split(',');
			} else {
				tempEra = this.props.eras;
			}
			eras = tempEra.map((era) => {
				return {
					id: era,
					name: era
				}
			});
		}
		this.setState({
			tags: tags,
			eras: eras
		});
	}

	onDeleteTag = async (i) => {
		const tags = this.state.tags.slice(0)
		tags.splice(i, 1)
		await this.setState({ tags });
		this.callback();
	}

	onAdditionTag = async (tag) => {
		if(!tag.id){
			tag = {
				id: tag.name,
				name: tag.name,
			}
		}
		const tags = [].concat(this.state.tags, tag)
		await this.setState({ tags });
		this.callback();
	}

	onDeleteEra = async (i) => {
		const eras = this.state.eras.slice(0)
		eras.splice(i, 1);
		await this.setState({ eras });
		this.callback();
	}

	onAdditionEra = async (tag) => {
		if(!tag.id){
			tag = {
				id: tag.name,
				name: tag.name,
			}
		}
		const eras = [].concat(this.state.eras, tag);
		await this.setState({ eras });
		this.callback();
	}

	tagClick = e => {
		const btn = e.currentTarget;
		this.onAdditionTag({
			id: btn.getAttribute('data-id'),
			name: btn.getAttribute('data-name'),
		});
		this.hideModal(e);
	}

	eraClick = e => {
		const btn = e.currentTarget;
		this.onAdditionEra({
			id: btn.getAttribute('data-id'),
			name: btn.getAttribute('data-name'),
		});
		this.hideModal(e);
	}

	showModal = e => {
		e.preventDefault();
		const trigger = e.currentTarget;
		const target = document.querySelector(trigger.getAttribute('href'));
		target.classList.add('active')
	}

	hideModal = e => {
		e.preventDefault();
		e.currentTarget.closest('.tags-all-list').classList.remove('active');
	}

	callback = () => {
		if(this.props.callback){
			this.props.callback({
				tags: this.state.tags,
				eras: this.state.eras
			});
		}
	}

	render() {
		return(
			<div id={this.state.parentID + '-wrap'}>
				<div id={this.state.parentID}>
					<div className="mb-3">
						<p className="m-0">Please set and select tags and eras for this tour. This will help travellers to find this tour.</p>
					</div>

					<div className="mb-4">
						<div className="mb-2">
							<strong>Tags</strong> - <a onClick={this.showModal} className="small text-borderline color-light" href="#allSeriesTags">All tags</a>
						</div>
						<ReactTags
							allowNew={true}
							placeholderText="Search..."
							ref={this.reactTags}
							tags={this.state.tags}
							suggestions={this.state.tagSuggestions}
							onDelete={this.onDeleteTag.bind(this)}
							onAddition={this.onAdditionTag.bind(this)} />
							<span className="small color-light">Examples: history, Kenya, Freedom</span>
					</div>

					<div>
						<div className="mb-2">
							<strong>Eras</strong> - <a onClick={this.showModal} className="small text-borderline color-light" href="#allSeriesEras">All eras</a>
						</div>
						<ReactTags
							allowNew={true}
							placeholderText="Search..."
							ref={this.reactTags}
							tags={this.state.eras}
							suggestions={this.state.eraSuggestions}
							onDelete={this.onDeleteEra.bind(this)}
							onAddition={this.onAdditionEra.bind(this)} />
							<span className="small color-light">Examples: 17th Century, 1850-1860, Revolt Era, Ottoman Empire</span>
					</div>
				</div>


				<div className="tags-all-list" id="allSeriesTags">
					<button className="close-tag-list" onClick={this.hideModal}><i className="fal fa-times" /></button>
					<div className="tags-all-list-inner">
						<header><div>Click on a tag to select:</div></header>
						<Scrollbars style={{ width: '100%', height: '100%' }}>
							<ul>
								{
									this.state.tagSuggestions.map((tag, i) => {
										return (<li key={'tag-' + i}><button onClick={this.tagClick} className="btn" data-name={tag.name} data-id={tag.id}>{tag.name}</button></li>);
									})
								}
							</ul>
						</Scrollbars>
					</div>
				</div>

				<div className="tags-all-list" id="allSeriesEras">
					<button className="close-tag-list" onClick={this.hideModal}><i className="fal fa-times" /></button>
					<div className="tags-all-list-inner">
						<header><div>Click on an era to select:</div></header>
						<Scrollbars style={{ width: '100%', height: '100%' }}>
							<ul>
								{
									this.state.eraSuggestions.map((tag, i) => {
										return (<li key={'tag-' + i}><button onClick={this.eraClick} className="btn" data-name={tag.name} data-id={tag.id}>{tag.name}</button></li>);
									})
								}
							</ul>
						</Scrollbars>
					</div>
				</div>

			</div>

		)
	}

}
